/* eslint-disable prettier/prettier */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Especialidades from '~/pages/Especialidades';
import Ortopedia from '~/pages/Ortopedia';
import Fisioterapia from '~/pages/Fisioterapia';
import Treinamento from '~/pages/Treinamento';
import Biomecanica from '~/pages/Biomecanica';
import Baropodometria from '~/pages/Baropodometria';
import Acupuntura from '~/pages/Acupuntura';
import Massoterapia from '~/pages/Massoterapia';
import Pilates from '~/pages/Pilates';
import Osteopatia from '~/pages/Osteopatia';
import Ergonomia from '~/pages/Ergonomia';
import Rpg from '~/pages/Rpg';
import AssessoriaCorrida from '~/pages/AssessoriaCorrida';
import H5 from '~/pages/H5';
import Atletas from '~/pages/Atletas';
import Consultorios from '~/pages/Consultorios';
import QuemSomos from '~/pages/QuemSomos';
import Contato from '~/pages/Contato';
import Blog from '~/pages/Blog';
import Materias from '~/pages/Materias';
import PoliticaPrivacidade from '~/pages/PoliticaPrivacidade';


import Home from '~/pages/Home/index';

// import Page404 from '~/pages/Page404/';

export default function Routes() {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Home} />
      <Route path={`${process.env.PUBLIC_URL}/especialidades`} exact component={Especialidades} />
      <Route path={`${process.env.PUBLIC_URL}/especialidades/ortopedia`} component={Ortopedia} />
      <Route path={`${process.env.PUBLIC_URL}/especialidades/fisioterapia-ortopedica`} component={Fisioterapia} />
      <Route path={`${process.env.PUBLIC_URL}/especialidades/treinamento-funcional`} component={Treinamento} />
      <Route path={`${process.env.PUBLIC_URL}/especialidades/avaliacao-biomecanica`} component={Biomecanica} />
      <Route path={`${process.env.PUBLIC_URL}/especialidades/baropodometria`} component={Baropodometria} />
      <Route path={`${process.env.PUBLIC_URL}/especialidades/acupuntura`} component={Acupuntura} />
      <Route path={`${process.env.PUBLIC_URL}/especialidades/massoterapia`} component={Massoterapia} />
      <Route path={`${process.env.PUBLIC_URL}/especialidades/pilates`} component={Pilates} />
      <Route path={`${process.env.PUBLIC_URL}/especialidades/osteopatia`} component={Osteopatia} />
      <Route path={`${process.env.PUBLIC_URL}/especialidades/ergonomia-physic`} component={Ergonomia} />
      <Route path={`${process.env.PUBLIC_URL}/especialidades/rpg`} component={Rpg} />
      <Route path={`${process.env.PUBLIC_URL}/especialidades/assessoria-corrida`} component={AssessoriaCorrida} />
      <Route path={`${process.env.PUBLIC_URL}/h5-health`} component={H5} />
      <Route path={`${process.env.PUBLIC_URL}/atletas`} component={Atletas} />
      <Route path={`${process.env.PUBLIC_URL}/consultorios`} component={Consultorios} />
      <Route path={`${process.env.PUBLIC_URL}/quem-somos`} component={QuemSomos} />
      <Route path={`${process.env.PUBLIC_URL}/contato`} component={Contato} />
      <Route path={`${process.env.PUBLIC_URL}/politica-privacidade`} component={PoliticaPrivacidade} />
      <Route path={`${process.env.PUBLIC_URL}/blog`} exact component={Blog} />
      <Route path={`${process.env.PUBLIC_URL}/blog/:slug`} exact component={Materias} />

      {/* <Route path={`${process.env.PUBLIC_URL}/`} component={Page404} /> */}
    </Switch>
  );
}
