import React from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';
import { Container } from './styles';

import Equipamentos from '~/components/Equipamentos';
import Contato from '~/components/Contato';

import ortopedia from '~/assets/icones/ortopedia.svg';
import fisioterapia from '~/assets/icones/fisioterapia.svg';
import treinamento from '~/assets/icones/treinamento-funcional.svg';
import biomecanica from '~/assets/icones/biomecanica.svg';
import baropodometria from '~/assets/icones/baropodometria.svg';
import acupuntura from '~/assets/icones/acupuntura.svg';
import massoterapia from '~/assets/icones/massoterapia.svg';
import pilates from '~/assets/icones/pilates.svg';
import osteopatia from '~/assets/icones/osteopatia.svg';
import ergonomia from '~/assets/icones/ergonomia.svg';
import rpg from '~/assets/icones/rpg.svg';
import assessoria from '~/assets/icones/assessoriaCorrida.svg';

function Especialidades() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – Especialidades</title>
          <meta
            name="description"
            content="Clínica Physic é especialista em tratamentos personalizados de medicina esportiva, fisioterapia, ortopedia e mais!"
          />
        </Helmet>
        <div className="bg-espec">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  ESPECIALIDADES PHYSIC
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center mt-4">
              <h2 className="h5 h4-md font-weight-bold">
                <em>
                  Clínica Physic é especialista em tratamentos personalizados de
                  medicina esportiva, fisioterapia, ortopedia e mais!
                </em>
              </h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-6 col-md-4 col-lg-3 mt-4 mt-lg-5">
              <Link to={`${process.env.PUBLIC_URL}/especialidades/ortopedia`}>
                <div className="text-center">
                  <img src={ortopedia} alt="Ortopedia" className="w-75" />
                  <h3 className="h6 h4-md font-weight-bold mt-3">ORTOPEDIA</h3>
                  <h6 className="mt-2 mt-lg-4 border-btn px-3 d-inline-block py-2">
                    Saiba mais
                  </h6>
                </div>
              </Link>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mt-4 mt-lg-5">
              <Link
                to={`${process.env.PUBLIC_URL}/especialidades/fisioterapia-ortopedica`}
              >
                <div className="text-center">
                  <img src={fisioterapia} alt="Fisioterapia" className="w-75" />
                  <h3 className="h6 h4-md font-weight-bold mt-3">
                    FISIOTERAPIA
                  </h3>
                  <h6 className="mt-2 mt-lg-4 border-btn px-3 d-inline-block py-2">
                    Saiba mais
                  </h6>
                </div>
              </Link>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mt-4 mt-lg-5">
              <Link
                to={`${process.env.PUBLIC_URL}/especialidades/treinamento-funcional`}
              >
                <div className="text-center">
                  <img
                    src={treinamento}
                    alt="Treinamento Funcional"
                    className="w-75"
                  />
                  <h3 className="h6 h4-md font-weight-bold mt-3">
                    TREINAMENTO FUNCIONAL
                  </h3>
                  <h6 className="mt-1 mt-lg-n1 border-btn px-3 d-inline-block py-2">
                    Saiba mais
                  </h6>
                </div>
              </Link>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mt-4 mt-lg-5">
              <Link
                to={`${process.env.PUBLIC_URL}/especialidades/avaliacao-biomecanica`}
              >
                <div className="text-center">
                  <img
                    src={biomecanica}
                    alt="Teste de Biomecânica"
                    className="w-75"
                  />
                  <h3 className="h6 h4-md font-weight-bold mt-3">
                    TESTE DE BIOMECÂNICA
                  </h3>
                  <h6 className="mt-1 mt-lg-n1 border-btn px-3 d-inline-block py-2">
                    Saiba mais
                  </h6>
                </div>
              </Link>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mt-4 mt-lg-5">
              <Link
                to={`${process.env.PUBLIC_URL}/especialidades/baropodometria`}
              >
                <div className="text-center">
                  <img
                    src={baropodometria}
                    alt="Baropodometria"
                    className="w-75"
                  />
                  <h3 className="h6 h4-md font-weight-bold mt-3">
                    BAROPODOMETRIA
                  </h3>
                  <h6 className="mt-1 border-btn px-3 d-inline-block py-2">
                    Saiba mais
                  </h6>
                </div>
              </Link>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mt-4 mt-lg-5">
              <Link to={`${process.env.PUBLIC_URL}/especialidades/acupuntura`}>
                <div className="text-center">
                  <img src={acupuntura} alt="Acupuntura" className="w-75" />
                  <h3 className="h6 h4-md font-weight-bold mt-3">ACUPUNTURA</h3>
                  <h6 className="mt-1 border-btn px-3 d-inline-block py-2">
                    Saiba mais
                  </h6>
                </div>
              </Link>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mt-4 mt-lg-5">
              <Link
                to={`${process.env.PUBLIC_URL}/especialidades/massoterapia`}
              >
                <div className="text-center">
                  <img src={massoterapia} alt="Massoterapia" className="w-75" />
                  <h3 className="h6 h4-md font-weight-bold mt-3">
                    MASSOTERAPIA
                  </h3>
                  <h6 className="mt-1 border-btn px-3 d-inline-block py-2">
                    Saiba mais
                  </h6>
                </div>
              </Link>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mt-4 mt-lg-5">
              <Link to={`${process.env.PUBLIC_URL}/especialidades/pilates`}>
                <div className="text-center">
                  <img src={pilates} alt="Pilates" className="w-75" />
                  <h3 className="h6 h4-md font-weight-bold mt-3">PILATES</h3>
                  <h6 className="mt-1 border-btn px-3 d-inline-block py-2">
                    Saiba mais
                  </h6>
                </div>
              </Link>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mt-4 mt-lg-5">
              <Link to={`${process.env.PUBLIC_URL}/especialidades/osteopatia`}>
                <div className="text-center">
                  <img src={osteopatia} alt="Osteopatia" className="w-75" />
                  <h3 className="h6 h4-md font-weight-bold mt-3">OSTEOPATIA</h3>
                  <h6 className="mt-1 border-btn px-3 d-inline-block py-2">
                    Saiba mais
                  </h6>
                </div>
              </Link>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mt-4 mt-lg-5">
              <Link
                to={`${process.env.PUBLIC_URL}/especialidades/ergonomia-physic`}
              >
                <div className="text-center">
                  <img src={ergonomia} alt="Ergonomia" className="w-75" />
                  <h3 className="h6 h4-md font-weight-bold mt-3">ERGONOMIA</h3>
                  <h6 className="mt-1 border-btn px-3 d-inline-block py-2">
                    Saiba mais
                  </h6>
                </div>
              </Link>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mt-4 mt-lg-5 mb-4">
              <Link to={`${process.env.PUBLIC_URL}/especialidades/rpg`}>
                <div className="text-center">
                  <img src={rpg} alt="RPG" className="w-75" />
                  <h3 className="h6 h4-md font-weight-bold mt-3">RPG</h3>
                  <h6 className="mt-1 border-btn px-3 d-inline-block py-2">
                    Saiba mais
                  </h6>
                </div>
              </Link>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mt-4 mt-lg-5 mb-4">
              <Link to={`${process.env.PUBLIC_URL}/especialidades/assessoria-corrida`}>
                <div className="text-center">
                  <img src={assessoria} alt="Assessoria de Corrida" className="w-75" />
                  <h3 className="h6 h4-md font-weight-bold mt-3">Assessoria de Corrida</h3>
                  <h6 className="mt-1 border-btn px-3 d-inline-block py-2">
                    Saiba mais
                  </h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Container>
      <Equipamentos />
      <Contato />
    </>
  );
}

export default Especialidades;
