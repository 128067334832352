import React from 'react';
import { Helmet } from 'react-helmet';

import Contato from '~/components/Contato';

import { Container, BgGray } from './styles';

function Contatos() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – Contato</title>
          <meta
            name="description"
            content="Clínica Physic está localizada na cidade de São Paulo no bairro Ibirapuera. Entre em contato e agende já a sua consulta."
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  PHYSIC
                </h1>
                <h2 className="h5 h4-md text-white font-italic text-center bold normal">
                  Descubra o que podemos fazer para melhorar sua{' '}
                  <br className="d-none d-sm-block" />
                  saúde e aumentar a sua performance para que você{' '}
                  <br className="d-none d-sm-block" />
                  vença seus limites
                </h2>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <BgGray>
        <div className="container-fluid">
          <div className="row justify-content-center justify-content-lg-end">
            <div className="col col-sm-8 col-lg-4 col-xxl-3 py-lg-5">
              <div className="col-12 d-lg-flex text-center text-lg-left">
                <h2 className="h1 display-lg-4 font-weight-bold pt-0 pt-lg-5 mg-borda">
                  <span data-texto="ONDE" className="left absolute-mobile">
                    ONDE <br />
                  </span>
                  <span className="baixo absolute-mobile" data-baixo="ESTAMOS?">
                    ESTAMOS?
                  </span>
                </h2>
              </div>

              <div className="col col-sm-9 col-lg-10 pb-lg-5 mt-9">
                <p className="h5 h6-md pt-4 mb-lg-0">
                  Av. República do Líbano, 592 | Ibirapuera <br />
                  São Paulo/SP | CEP: 04502-000
                </p>
                <p className="h5 h6-md pt-4 mb-lg-0">
                  <span className="h5 h6-md red">TELEFONE:</span>{' '}
                  <a
                    href="tel:1135966650"
                    onClick={() => window.gtag_report_conversion_tel()}
                  >
                    (11) 3596-6650
                  </a>
                </p>
                <p className="h5 h6-md pt-4 mb-lg-0">
                  <span className="h5 h6-md red">
                    ATENDIMENTO: <br />
                  </span>
                  Segunda a sexta: das 07h às 21h <br />
                  Sábado: das 08h às 13h
                </p>
                <p className="h5 h6-md py-4">
                  <span className="h5 h6-md red">E-mail: </span>{' '}
                  <a href="mailto:contato@clinicaphysic.com.br">
                    contato@clinicaphysic.com.br
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-7 pr-lg-0 d-none d-lg-block">
              <div className="poligonal h-100 w-100">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.5167814152273!2d-46.666305785383294!3d-23.58579236837926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce59e2497cfdd3%3A0x9493af79cde317c1!2sClinica%20Physic%20-%20Medicina%2C%20Fisioterapia%20e%20Treinamento%20Funcional!5e0!3m2!1spt-BR!2sbr!4v1620998447664!5m2!1spt-BR!2sbr"
                  allowFullScreen=""
                  loading="lazy"
                  className="w-100 h-100"
                  title="Mapa Google"
                />
              </div>
            </div>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.5167814152273!2d-46.666305785383294!3d-23.58579236837926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce59e2497cfdd3%3A0x9493af79cde317c1!2sClinica%20Physic%20-%20Medicina%2C%20Fisioterapia%20e%20Treinamento%20Funcional!5e0!3m2!1spt-BR!2sbr!4v1620998447664!5m2!1spt-BR!2sbr"
          allowFullScreen=""
          loading="lazy"
          className="w-100 h-425 d-block d-lg-none"
          title="Mapa Google"
        />
      </BgGray>
      <Contato />
    </>
  );
}

export default Contatos;
