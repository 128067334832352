import styled from 'styled-components';

import banner from '~/assets/banners-paginas/banner-consultorio.png';
import mobile from '~/assets/banners-paginas/banner-consultorios-mobile.png';
import experiencia from '~/assets/metodos/bg-experiencia.png';

export const Container = styled.div`
  p {
    color: #333;
  }

  h2,
  h3 {
    color: #841619;
  }

  .red {
    color: #841619;
    font-weight: bold;
  }

  h2 {
    span::after {
      color: transparent;
      -webkit-text-stroke: 1px rgba(167, 171, 173, 0.52);
      display: block;
      content: attr(data-texto);
      z-index: -1;
      position: absolute;
      left: 11px;
      top: 4px;
    }
  }

  .banner {
    background-image: url(${banner});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
    margin-top: 31px;
  }

  .mg-top {
    margin-top: 150px;
  }

  .titulo {
    span {
      color: transparent;
      -webkit-text-stroke: 1.5px #333;
    }
  }
  .bold {
    font-weight: bold;
  }

  @media screen and (min-width: 1400px) {
    .mg-top {
      margin-top: 250px;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      span::after {
        left: -3px;
        top: 3px;
      }
    }
    .mg-borda {
      margin-right: 250px;
      margin-top: 30px;
    }

    .absolute-mobile {
      position: absolute;
    }
  }

  @media screen and (max-width: 768px) {
    .banner {
      background-image: url(${mobile});
      background-position: center right;
    }

    .mg-top {
      margin-top: 250px;
    }

    .normal {
      font-weight: normal;
    }
  }
`;

export const BgGray = styled.div`
  background-color: #e7e7e8;
  z-index: 0;
  position: relative;

  h2 {
    span::after {
      color: transparent;
      -webkit-text-stroke: 1px rgba(167, 171, 173, 0.52);
      display: block;
      content: attr(data-texto);
      z-index: -1;
      position: absolute;
      left: 12px;
      top: 4px;
    }
  }

  ul li,
  p {
    color: #333;
  }

  h2,
  h3 {
    color: #841619;
  }

  @media screen and (max-width: 991px) {
    h2 {
      span::after {
        left: -3px;
        top: 3px;
      }
    }
    .mg-borda {
      margin-right: 325px;
      margin-top: 30px;
    }

    .absolute-mobile {
      position: absolute;
    }

    .mt-9 {
      margin-top: 9rem;
    }
  }
`;

export const Medicina = styled.div`
  p {
    color: #333;
  }

  h2,
  h3 {
    color: #841619;
  }

  .red {
    color: #841619;
    font-weight: bold;
  }

  h2 {
    span::after {
      color: transparent;
      -webkit-text-stroke: 1px rgba(167, 171, 173, 0.52);
      display: block;
      content: attr(data-texto);
      z-index: -1;
      position: absolute;
      left: 11px;
      top: 4px;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      span::after {
        left: -3px;
        top: 3px;
      }
    }
    .mg-borda {
      margin-right: 266px;
      margin-top: 30px;
    }

    .absolute-mobile {
      position: absolute;
    }

    .mt-9 {
      margin-top: 9rem;
    }
  }
`;

export const Consultorio = styled.div`
  background-color: #e7e7e8;

  h2,
  h3 {
    color: #841619;
  }

  .titulo {
    span {
      color: transparent;
      -webkit-text-stroke: 1.5px #333;
    }
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-dots li button:before {
    font-size: 15px;
    color: #70707080;
  }

  .slick-dots li.slick-active button:before {
    color: #707070;
  }

  @media screen and (max-width: 991px) {
    .w-94 {
      width: 94% !important;
    }
  }
`;

export const Estrutura = styled.div`
  background-color: #e7e7e8;

  h2,
  h3 {
    color: #841619;
  }

  .titulo {
    span {
      color: transparent;
      -webkit-text-stroke: 1.5px #333;
    }
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-dots li button:before {
    font-size: 15px;
    color: #70707080;
  }

  .slick-dots li.slick-active button:before {
    color: #707070;
  }

  @media screen and (max-width: 991px) {
    .w-94 {
      width: 94% !important;
    }
  }
`;

export const Experiencia = styled.div`
  background-image: url(${experiencia});
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 5% 0;
  z-index: 0;
  position: relative;

  .color,
  p {
    color: #333;
  }

  h2 {
    color: #841619;
  }

  h2 {
    span::after {
      color: transparent;
      -webkit-text-stroke: 1px rgba(167, 171, 173, 0.52);
      display: block;
      content: attr(data-texto);
      z-index: -1;
      position: absolute;
      left: 11px;
      top: 4px;
    }
  }
`;
