import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { FaWhatsapp } from 'react-icons/fa';
import { FiPhone } from 'react-icons/fi';

import { Container, MenuButton, Menu } from './styles';

import logo from '~/assets/logo-header/logo-header.png';
import logoMenu from '~/assets/logo-header/logo-mobile.png';
import homeIco from '~/assets/icones/home-mobile.svg';
import especialidadesIco from '~/assets/icones/especialidades-mobile.svg';
import h5Ico from '~/assets/icones/h5-mobile.svg';
import atletasIco from '~/assets/icones/atletas-mobile.svg';
import consultoriosIco from '~/assets/icones/consultorios-mobile.svg';
import somosIco from '~/assets/icones/somos-mobile.svg';
import blogIco from '~/assets/icones/blog-mobile.svg';
import contatoIco from '~/assets/icones/contato-mobile.svg';
import whatsIco from '~/assets/icones/whatsapp-mobile.svg';

function Header() {
  const location = useLocation();
  const [openedMobile, setOpenedMobile] = useState(false);

  useEffect(() => {
    setOpenedMobile(false);
  }, [location]);

  return (
    <Container className="p-relative">
      <div className="bg-white fixed-top py-2 shadow-sm">
        <div className="container px-0 px-lg-3">
          <div className="col-12 text-right d-flex justify-content-end justify-content-lg-between align-items-center">
            <a
              href="https://api.whatsapp.com/send?phone=551135966650"
              target="blank"
              onClick={() => window.gtag_report_conversion_whats()}
            >
              <FaWhatsapp size={35} className="color-whats mr-lg-3 d-lg-none" />
            </a>
           <a
              href="tel:1135966652"
              className="px-4 py-2 bg-btn ml-2 ml-lg-0 d-block d-lg-none"
              onClick={() => window.gtag_report_conversion_tel()}
            >
              LIGAR AGORA
            </a>
            <div className="d-flex">
            { /* <a
                href="tel:1135966652"
                className="px-3 py-2 bg-btn-gray ml-2 ml-lg-0 d-none d-lg-block mr-2"
                onClick={() => window.gtag_report_conversion_tel()}
              >
                <FiPhone size={22} className="mx-1" /> (11) 3596-6652
            </a> */}
              <a
                href="https://api.whatsapp.com/send?phone=551135966650"
                target="blank"
                onClick={() => window.gtag_report_conversion_whats()}
                className="px-4 py-2 bg-btn ml-2 ml-lg-0 d-none d-lg-flex "
              >
                <FaWhatsapp size={24} className="mr-1" /> AGENDE SUA AVALIAÇÃO
              </a>
            </div>
          </div>
          <nav className="p-unset navbar navbar-toggleable-sm navbar-expand-lg py-0 px-0 px-lg-3">
            <div className="p-unset col-12 d-flex justify-content-around justify-content-lg-between align-items-center px-0">
              <Link to={`${process.env.PUBLIC_URL}/`}>
                <img
                  src={logo}
                  className="ml-lg-0 ml-3 mt-2 mt-lg-0 w-50 w-lg-75"
                  alt="Logo Physic"
                />
              </Link>
              <MenuButton
                type="button"
                className="d-block d-lg-none mr-3 mt-2 mt-lg-0"
                active={openedMobile}
                onClick={() => setOpenedMobile(!openedMobile)}
              >
                <span />
                <span />
              </MenuButton>
              <Menu
                active={openedMobile}
                className="nav navbar-nav p-absolute p-lg-relative"
              >
                <div className="text-center my-auto d-block d-lg-none">
                  <Link to={`${process.env.PUBLIC_URL}/`}>
                    <img src={logoMenu} alt="Logo Physic" />
                  </Link>
                </div>
                <ul className="d-flex flex-column flex-lg-row justify-content-start justify-content-lg-center pt-3 pt-lg-0 mb-lg-0">
                  <li className="p-2 px-lg-0 nav-item mr-lg-3">
                    <Link
                      to={`${process.env.PUBLIC_URL}/`}
                      className="font-weight-bold"
                    >
                      Home
                      <img
                        src={homeIco}
                        alt="Ícone home"
                        className="mx-4 d-block d-lg-none"
                      />
                    </Link>
                  </li>
                  <li className="p-2 px-lg-0 nav-item mr-lg-3">
                    <Link
                      to={`${process.env.PUBLIC_URL}/especialidades`}
                      className="font-weight-bold"
                    >
                      Especialidades
                      <img
                        src={especialidadesIco}
                        alt="Ícone especialidades"
                        className="mx-4 d-block d-lg-none"
                      />
                    </Link>
                  </li>

                  <li className="p-2 px-lg-0 nav-item mr-lg-3">
                    <Link
                      to={`${process.env.PUBLIC_URL}/h5-health`}
                      className="font-weight-bold"
                    >
                      H5 Health
                      <img
                        src={h5Ico}
                        alt="Ícone H5"
                        className="mx-4 d-block d-lg-none"
                      />
                    </Link>
                  </li>
                  <li className="p-2 px-lg-0 nav-item mr-lg-3">
                    <Link
                      to={`${process.env.PUBLIC_URL}/atletas`}
                      className="font-weight-bold"
                    >
                      Atletas
                      <img
                        src={atletasIco}
                        alt="Ícone Atletas"
                        className="mx-4 d-block d-lg-none"
                      />
                    </Link>
                  </li>
                  <li className="p-2 px-lg-0 nav-item mr-lg-3">
                    <Link
                      to={`${process.env.PUBLIC_URL}/consultorios`}
                      className="font-weight-bold"
                    >
                      Consultórios
                      <img
                        src={consultoriosIco}
                        alt="Ícone Consultórios"
                        className="mx-4 d-block d-lg-none"
                      />
                    </Link>
                  </li>
                  <li className="p-2 px-lg-0 nav-item mr-lg-3">
                    <Link
                      to={`${process.env.PUBLIC_URL}/quem-somos`}
                      className="font-weight-bold"
                    >
                      Quem Somos
                      <img
                        src={somosIco}
                        alt="Ícone Quem Somos"
                        className="mx-4 d-block d-lg-none"
                      />
                    </Link>
                  </li>
                  <li className="p-2 px-lg-0 nav-item mr-lg-3">
                    <Link
                      to={`${process.env.PUBLIC_URL}/blog`}
                      className="font-weight-bold"
                    >
                      Blog
                      <img
                        src={blogIco}
                        alt="Ícone Blog"
                        className="mx-4 d-block d-lg-none"
                      />
                    </Link>
                  </li>
                  <li className="p-2 px-lg-0 nav-item mr-lg-0">
                    <Link
                      to={`${process.env.PUBLIC_URL}/contato`}
                      className="font-weight-bold"
                    >
                      Contato
                      <img
                        src={contatoIco}
                        alt="Ícone Contato"
                        className="mx-4 d-block d-lg-none"
                      />
                    </Link>
                  </li>
                  <li className="p-2 px-lg-0 nav-item mr-lg-3 d-block d-lg-none">
                    <a
                      href="https://api.whatsapp.com/send?phone=551135966650"
                      className="font-weight-bold"
                      onClick={() => window.gtag_report_conversion_whats()}
                    >
                      Whatsapp
                      <img
                        src={whatsIco}
                        alt="Ícone Whatsapp"
                        className="mx-4 d-block d-lg-none"
                      />
                    </a>
                  </li>
                </ul>
              </Menu>
            </div>
          </nav>
        </div>
      </div>
    </Container>
  );
}

export default Header;
