import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

import { parseISO, format } from 'date-fns';
import api from '~/services/api';

import { Container } from './styles';
import Contato from '~/components/Contato';
import Categorias from '~/components/Categorias';

function Materias() {
  const [blog, setBlog] = useState([]);
  const [materias, setMaterias] = useState([]);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    api.get(`/blog/${params.slug}`).then(response => {
      const data = {
        id: response.data.id,
        title: response.data.title,
        created_at: format(parseISO(response.data.created_at), 'dd/MM/yyyy'),
        content: response.data.content,
        slug: response.data.slug,
        banner: response.data.banner,
      };
      setBlog(data);
    });

    api.get('/blog/more').then(response => {
      const data = response.data.map(materia => {
        const description = materia.content
          .replace(/<[^>]*>?/gm, '')
          .split('.');
        return {
          id: materia.id,
          title: materia.title,
          thumbnail: materia.thumbnail,
          created_at: format(parseISO(materia.created_at), 'dd/MM/yyyy'),
          content: `${description[0]}…`,
          slug: materia.slug,
        };
      });
      setMaterias(data);
    });
  }, [params]);

  const handleSelect = useCallback(
    category_id => {
      localStorage.setItem('@physic/category', category_id);
      history.push(`${process.env.PUBLIC_URL}/blog`);
    },
    [history]
  );
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – Blog</title>
          <meta
            name="description"
            content="Clínica Physic – Blog sobre dicas para a saúde muscular, alívio de dores e como evitar lesões no dia a dia. Agende sua consulta!"
          />
        </Helmet>
        <Categorias onSelect={handleSelect} />
        <div className="container">
          <div className="row my-4">
            <div className="col-xl-9">
              <p className="data">{blog.created_at}</p>
              <h1 className="h4 text-uppercase">{blog.title}</h1>
              <img src={blog.banner} alt={blog.title} className="my-4" />
              <p
                dangerouslySetInnerHTML={{ __html: blog.content }}
                className="sub-titulo"
              />

              <div className="my-5">
                <a href="https://www.facebook.com/ClinicaPhysic/">
                  <FaFacebookF size={30} className="icons" />
                </a>
                <a
                  href="https://www.instagram.com/clinicaphysic/"
                  className="mx-4"
                >
                  <FaInstagram size={30} className="icons" />
                </a>
              </div>
            </div>

            <div className="col-lg-3 pl-lg-0 d-none d-xl-block">
              <div className="text-center text-white bg-red py-3">OUTROS</div>
              <div className="borda py-3">
                {materias.map(materia => (
                  <div className="d-flex align-items-center py-2">
                    <div className="col-5 px-1">
                      <img src={materia.thumbnail} alt={materia.title} />
                    </div>
                    <div className="col-7">
                      <p className="mb-0 data">{materia.created_at}</p>
                      <h2 className="h6 text-uppercase small">
                        {materia.title}
                      </h2>
                      <Link
                        to={`${process.env.PUBLIC_URL}/blog/${materia.slug}`}
                        className="btn btn-ler px-3 py-0"
                      >
                        Ler mais
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Contato />
    </>
  );
}

export default Materias;
