import React from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

import { Container, BgGray } from './styles';
import Contato from '~/components/Contato';
import Equipamentos from '~/components/Equipamentos';

import voltar from '~/assets/icones/icone-voltar.svg';
import eletroterapia from '~/assets/metodos/eletroterapia.png';

function Fisioterapia() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – Fisioterapia</title>
          <meta
            name="description"
            content="Clínica Physic é especialista em fisioterapia ortopédica, com um atendimento personalizado em frente ao parque do Ibirapuera. Agende sua consulta!"
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  FISIOTERAPIA ORTOPÉDICA
                </h1>
                <h2 className="my-5 my-lg-0 text-white font-italic text-center h5 h4-md font-weight-bold">
                  Tratamento individual para recuperação de lesões e desgastes
                </h2>
                <div className="text-center mt-lg-4">
                  <Link
                    to={`${process.env.PUBLIC_URL}/especialidades`}
                    className="text-white h4"
                  >
                    <img src={voltar} alt="Icone voltar" className="mr-2" />
                    Voltar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 px-lg-5 mt-4 mt-lg-0">
              <h3 className="h5 h6-lg mb-4 mb-lg-3 color">
                Excelente resultado para dores nas costas, lesão muscular e
                recuperação física.
              </h3>
              <h4 className="color h5 h6-lg mb-4 mb-lg-3">
                Atuamos na recuperação de lesões causadas por traumas, maus
                hábitos posturais ou atividades de vida diária.
              </h4>

              <h4 className="color h5 h6-lg mb-4 mb-lg-3">
                Utilizamos técnicas manuais para liberação miofascial, aumentar
                mobilidade, ativação muscular e diversas outras possibilidades
                de manipulação, com o objetivo de aliviar as dores do paciente.
              </h4>

              <h4 className="color h5 h6-lg mb-4 mb-lg-3">
                Também contamos com a eletroterapia, que são os aparelhos
                responsáveis pelo controle da dor, processos inflamatórios,
                entre outras aplicações, e que ajudam a deixar o paciente mais
                confortável enquanto se recupera de suas queixas.
              </h4>
            </div>
            <div className="col-lg-6">
              <img src={eletroterapia} alt="Eletroterapia" />
            </div>
          </div>
        </div>
      </Container>
      <BgGray>
        <div className="container">
          <div className="row pb-4 justify-content-center">
            <div className="col-11 col-sm-7 d-flex justify-content-center">
              <h2 className="h1 display-sm-4 font-weight-bold pt-5">
                <span data-texto="TRATAMENTOS:">TRATAMENTOS:</span>
              </h2>
            </div>
            <div className="col-lg-6">
              <div className="col-lg-10 my-4">
                <h3 className="h4 font-weight-bold">Dores de cabeça:</h3>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Cefaleia associada à tensão emocional e muscular aguda ou
                  crônica;
                </h4>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Enxaqueca (migrânea): dor de cabeça intensa, latejante,
                  acompanhada de náuseas, vômitos, sensibilidade à luz e som.
                </h4>
              </div>
              <div className="col-lg-10 my-4">
                <h3 className="h4 font-weight-bold">Dores no pescoço:</h3>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Hérnia de disco no pescoço, que causa dor ao realizar o
                  movimento e irradia para os ombros, braços e antebraços.
                </h4>
              </div>
              <div className="col-lg-10 my-4">
                <h3 className="h4 font-weight-bold">Dores no ombro:</h3>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Síndrome do impacto, bursite, luxação, tendinite, fraturas,
                  artrose, limitando consideravelmente o ombro e prejudicando as
                  atividades diárias do paciente.
                </h4>
              </div>
              <div className="col-lg-10 my-4">
                <h3 className="h4 font-weight-bold">Dores na coluna:</h3>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Artrose, hérnia de disco, protrusão de disco, escoliose,
                  cifose, hiperlordose;
                </h4>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Espondilite anquilosante: provoca rigidez parcial ou total,
                  gerando problemas na coluna vertebral, impossibilitando os
                  movimentos e causando dor;
                </h4>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Cervicalgia, mais conhecida como dor na cervical.
                </h4>
              </div>
              <div className="col-lg-10 my-4">
                <h3 className="h4 font-weight-bold">Dores na lombar</h3>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Lombalgias (dores agudas e crônicas) causadas por desvios
                  posturais ou carregamento de peso inadequado ou até hérnia de
                  disco;
                </h4>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Ciatalgia que provoca dores irradiadas na perna, quadril ou
                  nádegas, chegando a atingir até os pés.
                </h4>
              </div>
              <div className="col-lg-10 my-4">
                <h3 className="h4 font-weight-bold">Dores de cotovelo</h3>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Epicondilite lateral e medial, conhecida como cotovelo de
                  tenista ou cotovelo de golfista: dor na região lateral e
                  medial do cotovelo por excesso de repetições de movimento e
                  esforço;
                </h4>
                <h4 className="color pl-lg-3 h5 h6-md">Bursite no cotovelo.</h4>
              </div>
              <div className="col-lg-10 my-4">
                <h3 className="h4 font-weight-bold">Dores no quadril:</h3>

                <h4 className="color pl-lg-3 h5 h6-md">
                  Artrose, artroplastia, tendinite de impacto e bursite
                  trocantérica.
                </h4>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col-lg-10 my-4">
                <h3 className="h4 font-weight-bold">
                  Dores nas mãos, punhos e antebraços:
                </h3>
                <h4 className="color pl-lg-3 h5 h6-md">Lesões e entorses;</h4>
                <h4 className="color pl-lg-3 h5 h6-md">Tendinites;</h4>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Síndrome do túnel do carpo;
                </h4>
                <h4 className="color pl-lg-3 h5 h6-md">Artrite e artrose.</h4>
              </div>
              <div className="col-lg-10 my-4">
                <h3 className="h4 font-weight-bold">Dores no joelho:</h3>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Tendinite e bursite patelar: dores causadas por inflamações;
                </h4>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Entorses (distensões) e osteoartrose (inflamação da
                  cartilagem) do joelho;
                </h4>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Ligamentos, menisco, tendinite, artrose e condropatias.
                </h4>
              </div>
              <div className="col-lg-10 my-4">
                <h3 className="h4 font-weight-bold">
                  Dores nos pés e tornozelo:
                </h3>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Fascite plantar: inflamação da musculatura da planta do pé;
                </h4>
                <h4 className="color pl-lg-3 h5 h6-md">Esporão de calcâneo;</h4>
                <h4 className="color pl-lg-3 h5 h6-md">Entorses.</h4>
              </div>
              <div className="col-lg-10 my-4">
                <h3 className="h4 font-weight-bold">Dores de reumatismo:</h3>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Dores articulares: conhecidas como dores nas juntas.
                </h4>
              </div>
              <div className="col-lg-10 my-4">
                <h3 className="h4 font-weight-bold">Dores de artrite:</h3>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Artrite: dores que afetam a locomoção, inchaços e movimentos,
                  popularmente conhecida como “inflamação nas juntas”.
                </h4>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Dores causadas pela LER (Lesão por Esforço Repetitivo) e Dort
                  (Doenças Osteoarticulares Relacionadas ao Trabalho).
                </h4>
              </div>
              <div className="col-lg-10 my-4">
                <h3 className="h4 font-weight-bold">Lesões musculares:</h3>
                <h4 className="color pl-lg-3 h5 h6-md">
                  Distensões, estiramentos e contraturas.
                </h4>
                <h3 className="color pl-lg-3 h5 h6-md">
                  Fisioterapia no pré e pós-operatório.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </BgGray>
      <Equipamentos />
      <Contato />
    </>
  );
}

export default Fisioterapia;
