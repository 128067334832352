import React from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';

import Equipamentos from '~/components/Equipamentos';
import Contato from '~/components/Contato';

import { Container, Sergio, Hildebrando, Denis } from './styles';

import casal from '~/assets/metodos/h5-casal.png';
import bx from '~/assets/metodos/bx-2000.png';
import bxMobile from '~/assets/metodos/bx-2000-mobile.png';
import cabeca from '~/assets/icones/cabeca.svg';
import medicina from '~/assets/icones/medicina.svg';
import nutricao from '~/assets/icones/nutricao.svg';
import fisioterapia from '~/assets/icones/fisioterapia-h5.svg';
import educacao from '~/assets/icones/educacao.svg';
import psicologia from '~/assets/icones/psicologia.svg';
import consulta from '~/assets/icones/h5-consulta.svg';
import nutricional from '~/assets/icones/h5-nutricional.svg';
import avaliacao from '~/assets/icones/h5-avaliacao.svg';
import body from '~/assets/icones/h5-body.svg';
import emagrecimento from '~/assets/icones/emagrecimento.svg';
import musculo from '~/assets/icones/musculo.svg';
import desempenho from '~/assets/icones/desempenho.svg';
import saudavel from '~/assets/icones/saudavel.svg';
import disturbios from '~/assets/icones/disturbios.svg';
import tratamento from '~/assets/icones/tratamento.svg';
import sono from '~/assets/icones/sono.svg';
import gravidez from '~/assets/icones/gravidez.svg';
import sergio from '~/assets/metodos/sergio.png';
import hildebrando from '~/assets/metodos/hildebrando.png';
import denis from '~/assets/metodos/denis.png';

function H5() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – H5 Health</title>
          <meta
            name="description"
            content="Clínica Physic utiliza o método H5 Health criado pelo Dr. Sérgio Pistarino Jr. na medicina esportiva e tratamento da obesidade."
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  MÉTODO <br className="d-block d-md-none" />
                  H5 HEALTH:
                </h1>
                <h2 className="h4 h2-md text-white text-center">
                  Criado por Dr. Sérgio Pistarino&nbsp;Júnior
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center ">
            <div className="col-lg-2 text-center my-4 d-none d-lg-block">
              <img src={cabeca} alt="Ícone Método" />
            </div>
            <div className="col-lg-7 py-4 py-lg-0">
              <p className="h5 h6-lg">
                Desde 2012, venho atuando na área de medicina esportiva e no
                tratamento da obesidade (emagrecimento).{' '}
                <span className="d-none d-lg-block">
                  Aprendi muito na teoria e coloquei tudo que aprendi na
                  prática.
                </span>
              </p>
              <div className="d-flex justify-content-center align-items-center d-block d-lg-none">
                <img src={cabeca} alt="Ícone Método" className="w-45 py-4" />
                <p className="h5 pl-3">
                  Aprendi muito na teoria e coloquei tudo que aprendi na
                  prática.
                </p>
              </div>
              <p className="h5 h6-lg">
                Passei por grandes instituições e clubes e percebi que algumas
                coisas tinham que mudar. Resolvi então criar meu próprio método
                de atendimento.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-logo">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 mt-5">
                <h2 className="h1 display-lg-4 text-center font-weight-bold">
                  Por que
                  <br />
                  <span className="display-4 text-left display-lg-3 font-weight-bold lh-1">
                    HIGH FIVE <br className="d-block d-lg-none" />
                    HEALTH?
                  </span>
                </h2>
                <div className="ml-lg-4 pl-lg-2 mt-5 mt-lg-0">
                  <p className="h5 h6-md text-center text-lg-left">
                    Enquanto a palavra HIGH está relacionada com desempenho de
                    forma a trabalhar na sua alta performance, FIVE representa
                    as cinco especialidades da saúde envolvidas nesse programa:
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-around justify-content-lg-between">
              <div className="col-4 col-lg-2 text-center">
                <div className="num">
                  <img src={medicina} className="w-65" alt="Medicina" />
                  <span className="borda">1</span>
                </div>
                <h3 className="h6 h5-lg text-lg-left mt-2 mt-lg-0">Medicina</h3>
              </div>
              <div className="col-4 col-lg-2 text-center mt-5 mt-lg-0">
                <div className="num pt-lg-5">
                  <img src={nutricao} className="w-65" alt="Nutrição" />
                  <span className="borda">2</span>
                </div>
                <h3 className="h6 h5-lg text-lg-left mt-2 mt-lg-0">Nutrição</h3>
              </div>
              <div className="col-4 col-lg-2 text-center">
                <div className="num">
                  <img src={fisioterapia} className="w-65" alt="Fisioterapia" />
                  <span className="borda">3</span>
                </div>
                <h3 className="h6 h5-lg text-lg-left mt-2 mt-lg-0">
                  Fisioterapia
                </h3>
              </div>
              <div className="col-5 col-lg-2 text-center">
                <div className="num pt-lg-5">
                  <img src={educacao} className="w-65" alt="Educação Física" />
                  <span className="borda">4</span>
                </div>
                <h3 className="h6 h5-lg text-lg-left mt-2 mt-lg-0">
                  Educação Física
                </h3>
              </div>
              <div className="col-6 col-lg-2 text-center">
                <div className="num">
                  <img src={psicologia} className="w-65" alt="Psicologia" />
                  <span className="borda">5</span>
                </div>
                <h3 className="h6 h5-lg text-lg-left mt-2 mt-lg-0">
                  Psicologia
                </h3>
              </div>
            </div>
            <div className="row justify-content-center py-5 py-lg-0">
              <div className="col-lg-8 mt-lg-5">
                <p className="h5 h6-lg text-center text-lg-left">
                  E a expressão HIGH FIVE, de origem americana, significa “toca
                  aí”, fazendo menção à sensação de acolhimento, de “estamos
                  juntos nessa”. Afinal de contas, nossa missão é transformar
                  sua saúde, promovendo uma rotina mais saudável e muito mais
                  qualidade de&nbsp;vida.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <img src={casal} alt="Casal alongando" />
            </div>
            <div className="col-lg-5 my-5">
              <h2 className="h2 display-lg-4 font-weight-bold mb-4 mb-lg-2">
                O que é o <br />
                <span className="h2 display-lg-3 font-weight-bold">
                  HIGH FIVE <br className="d-none d-lg-block" />
                  HEALTH?
                </span>
              </h2>
              <p className="h5 h6-md mb-3">
                Um método de atendimento multidisciplinar, composto por médicos,
                nutricionistas e educadores físicos altamente capacitados para
                atuar de acordo com suas queixas e objetivos.
              </p>
              <p className="h5 h6-md mb-3">
                Medicina, nutrição e educação física juntas em um só lugar, com
                a mesma linha de pensamento, em prol dos seus objetivos que, a
                partir de agora, também são nossos!
              </p>
              <p className="h5 h6-md mb-3">
                E você, está preparado(a) para viver uma nova fase da sua saúde,
                repleta de bem-estar e desempenho físico?
              </p>
            </div>
          </div>
        </div>

        <div className="bg-logo-grande">
          <img
            src={bxMobile}
            alt="Body Metrix"
            className="pl-4 pt-5 d-block d-lg-none"
          />
          <div className="container-fluid pt-lg-5 pb-3">
            <div className="row justify-content-end align-items-center">
              <div className="col-lg-5 col-xxl-4">
                <h2 className="h2 display-lg-4 font-weight-bold">
                  Como é o <br />
                  <span className="h2 display-lg-3 font-weight-bold">
                    PROGRAMA?
                  </span>
                </h2>
                <p className="h5 h6-md mb-3">
                  Você passará por duas avaliações já inclusas no atendimento:
                  física e composição corporal por meio da tecnologia de
                  ultrassom BODYMETRIX, um equipamento com validação científica
                  internacional, que permite ver as camadas do seu corpo e medir
                  a quantidade de água, gordura, músculo e outros pontos
                  fundamentais para um melhor direcionamento do seu tratamento.
                </p>
                <p className="h5 h6-md mb-3">
                  Em seguida, você passará pela consulta nutricional para o
                  planejamento de uma boa alimentação, de acordo com os seus
                  objetivos e necessidades individuais.
                </p>
                <p className="h5 h6-md mb-3">
                  É também neste momento que receberá o pedido de exames
                  laboratoriais para que já os traga em seu retorno dentro do
                  período de 15 a 30 dias.
                </p>
                <p className="h5 h6-md mb-3">
                  Para que novas metas e objetivos sejam traçados, todos os seus
                  exames laboratoriais serão analisados além do exame físico e,
                  se necessário, haverá a prescrição de suplementos, medicações
                  tradicionais ou fitoterápicas.
                </p>
                <p className="h5 h6-md mb-3">
                  Nosso próximo encontro ocorrerá entre 45-60 dias e, então,
                  você passará pelo método completo novamente (nova consulta de
                  acompanhamento).
                </p>
              </div>
              <div className="col-lg-6 pr-0 d-none d-lg-block">
                <img src={bx} alt="Body Metrix" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="bg-exercicio pt-0 pt-lg-4">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <h2 className="display-lg-4 font-weight-bold lh-4 lh-1 mt-2 mt-lg-0">
                  Venha ser <br />
                  <span className="display-lg-3 font-weight-bold lh-4">
                    HIGH FIVE <br />
                    HEALTH!
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-5 col-sm-4 text-center mb-5">
              <h2 className="h1 display-sm-4 display-lg-3 font-weight-bold pt-4 pt-lg-5 sombra">
                <span data-texto="EQUIPE" className="color">
                  EQUIPE
                </span>
              </h2>
            </div>
          </div>
        </div>
      </Container>
      <Sergio>
        <img
          src={sergio}
          alt="Dr. Sergio Pistarino"
          className="d-block d-lg-none"
        />

        <div className="container-fluid">
          <div className="row justify-content-start">
            <div className="col-lg-6 pl-lg-0">
              <div className="poligonal h-100 w-100">
                <div className="h-100 w-100" />
              </div>
            </div>
            <div className="col-lg-5 col-xxl-4 py-5 my-lg-5">
              <h2 className="h3 h2-md font-weight-bold mb-0">
                DR. SÉRGIO PISTARINO
              </h2>
              <p>Médico do esporte | CRM: 166517 | RQE: 75099</p>
              <p className="h5 h6-md">
                Graduado em medicina desde 2012, com Residência Médica em
                Medicina Esportiva pela USP e pós-graduado em Fisiologia e
                Biomecânica do Esporte pela USP, possui vasta experiência médica
                voltada à prática de esportes e tratamento da obesidade. Também,
                participa como orador e palestrante de eventos relacionados ao
                emagrecimento saudável e à qualidade de vida, além de ser
                consultor técnico da Revista Sport Life e do Portal WebRun.
              </p>
              <h4 className="h5 font-weight-bold my-4">
                Conheça mais sobre a carreira do Dr. Sérgio Pistarino:
              </h4>
              <ul>
                <li>
                  • Médico da Associação Brasileira do Estudo da Obesidade -
                  ABESO
                </li>
                <li>
                  • Médico das Olimpíadas Rio 2016 (equipes de Remo, Canoagem e
                  Caiaque)
                </li>
                <li>
                  • Médico do corpo clínico da Copa do Mundo de Futebol Brasil
                  2014
                </li>
                <li>• Ex-Médico da Sociedade Esportiva Palmeiras</li>
                <li>• Ex-Médico da Clube de Regatas Flamengo</li>
                <li>• Ex-Médico do São Paulo Futebol Clube</li>
                <li>
                  • Fellowship do grupo de Obesidade do Hospital das Clínicas de
                  São Paulo – SP
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Sergio>
      <Hildebrando>
        <img
          src={hildebrando}
          alt="Hildebrando Padovan"
          className="d-block d-lg-none"
        />
        <div className="container-fluid">
          <div className="row justify-content-end align-items-center my-lg-5">
            <div className="col-lg-4 col-xxl-4 py-5 my-lg-5">
              <h2 className="h3 h2-md font-weight-bold mb-0">
                HILDEBRANDO PADOVAN
              </h2>
              <p>Nutricionista | CRN3: 45769</p>
              <ul>
                <li>
                  • Nutricionista, formado em 2014 pela Universidade Paulista
                  (Unip), também é pós graduado em fitoterapia funcional pela CP
                  Nutrição em 2018.
                </li>
                <li>
                  • Além disso, sua carreira conta com Cursos de Nutrição
                  Clínica e Nutrição Esportiva pela VP Nutrição.
                </li>
              </ul>
            </div>
            <div className="col-lg-6 pr-lg-0 d-none d-lg-block">
              <div className="poligonal h-100 w-100">
                <div className="h-100 w-100" />
              </div>
            </div>
          </div>
        </div>
      </Hildebrando>
      <Denis>
        <img src={denis} alt="Denis Bento" className="d-block d-lg-none" />
        <div className="container-fluid">
          <div className="row justify-content-start align-items-center">
            <div className="col-lg-6 pl-lg-0 d-none d-lg-block">
              <div className="poligonal h-100 w-100">
                <div className="h-100 w-100" />
              </div>
            </div>
            <div className="col-lg-4 col-xxl-4 py-5 my-lg-5">
              <h2 className="h3 h2-md font-weight-bold mb-0">DENIS BENTO</h2>
              <p>
                Profissional de Educação Física / Fisiologista |
                CREF:&nbsp;094068
              </p>
              <ul>
                <li>
                  • Profissional de Educação Física formado pela Universidade
                  Nove de Julho em 2012 e especialista em Fisiologia do
                  Exercício pela Universidade Federal de São Paulo em 2015.
                </li>
                <li>
                  • Tem conhecimentos na área de Avaliação Física, Treinamento
                  Funcional, Musculação e Corrida de Rua.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Denis>
      <Container>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 mt-5">
              <h2 className="h2 display-lg-4 text-center font-weight-bold">
                O que está incluso no&nbsp;método
                <br />
                <span className="text-left display-4 display-lg-3 font-weight-bold lh-3">
                  HIGH FIVE HEALTH?
                </span>
              </h2>
            </div>
          </div>
          <div className="row justify-content-between my-4 my-lg-5">
            <div className="col-3 col-lg-2 text-center">
              <img src={consulta} alt="Consulta Médica" className="w-xxl-100" />
              <h3 className="h6 mt-2">
                Consulta Médica com o Dr. Sérgio Pistarino
              </h3>
            </div>
            <div className="col-3 col-lg-2 text-center">
              <img
                src={nutricional}
                alt="Consulta Nutricional"
                className="w-75"
              />
              <h3 className="h6 mt-2">Consulta Nutricional</h3>
            </div>
            <div className="col-3 col-lg-2 text-center">
              <img src={avaliacao} alt="Avaliação Física" className="w-75" />
              <h3 className="h6 mt-2">Avaliação Física</h3>
            </div>
            <div className="col-3 col-lg-2 text-center">
              <img
                src={body}
                alt="Avaliação da composição corporal por ultrassom BODYMETRIX"
                className="w-xxl-100"
              />
              <h3 className="h6 mt-2">
                Avaliação da composição corporal por ultrassom BODYMETRIX
              </h3>
            </div>
          </div>
        </div>
        <div className="bg-gray">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 pt-2 pt-lg-5">
                <h2 className="display-lg-4 text-center font-weight-bold">
                  Para quem é <br className="d-block d-lg-none" />
                  indicado o método
                  <br />
                  <span className="text-left display-4 display-lg-3 font-weight-bold lh-3">
                    HIGH FIVE HEALTH?
                  </span>
                </h2>
              </div>
            </div>
            <div className="row justify-content-between mt-5 d-none d-lg-flex">
              <div className="col-lg-2 text-center">
                <img src={emagrecimento} alt="Emagrecimento" />
                <h3 className="h6 mt-2">
                  Emagrecimento e tratamento da&nbsp;obesidade
                </h3>
              </div>
              <div className="col-lg-2 text-center">
                <img src={musculo} alt="Ganho de massa muscular" />
                <h3 className="h6 mt-3">
                  Ganho de massa muscular (sarcopenia)
                </h3>
              </div>
              <div className="col-lg-2 text-center">
                <img src={desempenho} alt="Desempenho esportivo" />
                <h3 className="h6 mt-2">Desempenho esportivo</h3>
              </div>
              <div className="col-lg-2 text-center mt-lg-4">
                <img src={saudavel} alt="Vida saudável" />
                <h3 className="h6 mt-2 pt-1">
                  A busca por um estilo de vida mais saudável
                </h3>
              </div>
            </div>
            <div className="row justify-content-between mt-5 pb-5 d-none d-lg-flex">
              <div className="col-lg-2 text-center">
                <img src={disturbios} alt="Distúrbios alimentares" />
                <h3 className="h6 mt-3">
                  Distúrbios alimentares como compulsão alimentar, bulimia,
                  anorexia, ortorexia
                </h3>
              </div>
              <div className="col-lg-2 text-center">
                <img src={tratamento} alt="Tratamento de doenças" />
                <h3 className="h6 mt-2">
                  Tratamento de doenças crônicas como diabetes, hipertensão,
                  artroses entre outras
                </h3>
              </div>
              <div className="col-lg-2 text-center mt-lg-5 pt-lg-2">
                <img src={sono} alt="Melhoria qualidade de vida" />
                <h3 className="h6 mt-2">
                  Melhora da qualidade de vida como melhora do sono, ansiedade,
                  estresse e depressão
                </h3>
              </div>
              <div className="col-lg-2 text-center">
                <img src={gravidez} alt="Acompanhamento de gestantes" />
                <h3 className="h6 mt-2">
                  Acompanhamento de gestantes e <br />
                  recém-mamães
                </h3>
              </div>
            </div>

            <div className="d-block d-lg-none w-94 mx-auto py-5">
              <Slider {...settings}>
                <div className="px-2 text-center">
                  <img src={emagrecimento} alt="Emagrecimento" className="" />
                  <h3 className="h6 mt-2">
                    Emagrecimento e tratamento da&nbsp;obesidade
                  </h3>
                </div>
                <div className="px-2 text-center">
                  <img
                    src={musculo}
                    alt="Ganho de massa muscular"
                    className=""
                  />
                  <h3 className="h6 mt-3">
                    Ganho de massa muscular (sarcopenia)
                  </h3>
                </div>
                <div className="px-2 text-center">
                  <img
                    src={desempenho}
                    alt="Desempenho esportivo"
                    className="w-75"
                  />
                  <h3 className="h6 mt-2">Desempenho esportivo</h3>
                </div>
                <div className="px-2 text-center mt-2">
                  <img src={saudavel} alt="Vida saudável" className="" />
                  <h3 className="h6 mt-2 pt-1">
                    A busca por um estilo de vida mais saudável
                  </h3>
                </div>
                <div className="px-2 text-center">
                  <img
                    src={disturbios}
                    alt="Distúrbios alimentares"
                    className=""
                  />
                  <h3 className="h6 mt-3">
                    Distúrbios alimentares como compulsão alimentar, bulimia,
                    anorexia, ortorexia
                  </h3>
                </div>
                <div className="px-2 text-center">
                  <img
                    src={tratamento}
                    alt="Tratamento de doenças"
                    className="w-94 w-sm-75 w-lg-94"
                  />
                  <h3 className="h6 mt-1">
                    Tratamento de doenças crônicas como diabetes, hipertensão,
                    artroses entre outras
                  </h3>
                </div>
                <div className="px-2 text-center mt-4 pt-3">
                  <img
                    src={sono}
                    alt="Melhoria qualidade de vida"
                    className=""
                  />
                  <h3 className="h6 mt-2">
                    Melhora da qualidade de vida como melhora do sono,
                    ansiedade, estresse e depressão
                  </h3>
                </div>
                <div className="px-2 text-center">
                  <img
                    src={gravidez}
                    alt="Acompanhamento de gestantes"
                    className="w-75 mx-auto"
                  />
                  <h3 className="h6 mt-2 pt-1">
                    Acompanha- mento de gestantes e <br />
                    recém-mamães
                  </h3>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </Container>
      <Equipamentos />
      <Contato />
    </>
  );
}

export default H5;
