import styled from 'styled-components';

export const Container = styled.div`
  input,
  select {
    border-radius: 20px;
  }
  p,
  label {
    color: #333;
  }
  .bg-gradiente {
    background: #e5292a;
    background: linear-gradient(90deg, #e5292a, #b5295d);
  }
  .borda-palavras {
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    font-weight: bold;
  }

  .margin {
    input {
      margin: 0px 10px 3px 10px;
    }

    label {
      margin: 0;
    }
  }

  .btn-enviar {
    color: #fff;
    background: #e5292a;
    background: linear-gradient(180deg, #e5292a, #b5295d);
    padding: 6px 70px;
    border-radius: 20px;
  }
`;
