import React from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';
import Equipamentos from '~/components/Equipamentos';
import Contato from '~/components/Contato';

import { Container, BgGray } from './styles';

import voltar from '~/assets/icones/icone-voltar.svg';
import correndo from '~/assets/metodos/grupo-correndo.png';
import bioMobile from '~/assets/metodos/bio.png';

function Biomecanica() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – Biomecânica</title>
          <meta
            name="description"
            content="A Clínica Physic realiza avaliação biomecânica, avaliação postural e
            testes para evitar lesões e aumentar a performance de atletas.
            "
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  AVALIAÇÃO BIOMECÂNICA
                </h1>
                <h2 className="h5 h4-md my-5 my-lg-0 text-white font-italic text-center font-weight-bold">
                  Para atletas amadores e profissionais
                </h2>
                <div className="text-center mt-lg-4">
                  <Link
                    to={`${process.env.PUBLIC_URL}/especialidades`}
                    className="text-white h4"
                  >
                    <img src={voltar} alt="Icone voltar" className="mr-2" />
                    Voltar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center my-4">
              <h2 className="h4 h2-md font-weight-bold">
                MÉTODO PARA REDUZIR RISCOS DE LESÕES
                <br className="d-none d-lg-block" />E AUMENTAR A PERFORMANCE
              </h2>
            </div>
          </div>
        </div>
      </Container>
      <BgGray>
        <img
          src={bioMobile}
          alt="Correndo na esteira"
          className="d-block d-lg-none"
        />
        <div className="container-fluid">
          <div className="row justify-content-center justify-content-lg-end">
            <div className="col col-sm-9 col-lg-5 col-xxl-4">
              <div className="col-12 d-flex">
                <h2 className="display-lg-4 font-weight-bold pt-5">
                  <span data-texto="O QUE">
                    O QUE <br />
                  </span>
                  <span className="baixo" data-baixo="SERÁ FEITO?">
                    SERÁ FEITO?
                  </span>
                </h2>
              </div>

              <div className="col-lg-10 my-4">
                <p className="h5 h6-lg mb-3">
                  Excelente resultado para dores nas costas, lesão muscular e
                  recuperação física.
                </p>
                <p className="h5 h6-lg mb-3">
                  Atuamos na recuperação de lesões causadas por traumas, maus
                  hábitos posturais ou atividades de vida diária.
                </p>
                <p className="h5 h6-lg mb-3">
                  Utilizamos técnicas manuais para liberação miofascial,
                  aumentar mobilidade, ativação muscular e diversas outras
                  possibilidades de manipulação, com o objetivo de aliviar as
                  dores do paciente.
                </p>
                <p className="h5 h6-lg mb-3">
                  Também contamos com a eletroterapia, que são os aparelhos
                  responsáveis pelo controle da dor, processos inflamatórios,
                  entre outras aplicações, e que ajudam a deixar o paciente mais
                  confortável enquanto se recupera de suas queixas.
                </p>
              </div>

              <div className="col-lg-12 my-4">
                <h3 className="h4 font-weight-bold">O QUE É AVALIADO:</h3>
              </div>
              <div className="d-lg-flex">
                <div className="col-lg-6">
                  <ul className="mb-0 mb-lg-4">
                    <li>
                      <h4 className="h6 mb-0">• Angulações do tornozelo</h4>
                    </li>
                    <li>
                      <h4 className="h6 mb-0">• Joelho e quadril</h4>
                    </li>
                    <li>
                      <h4 className="h6 mb-0">• Inclinação da pelve</h4>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 px-lg-0">
                  <ul>
                    <li>
                      <h4 className="h6 mb-0">
                        • Comprimento do passo e da passada
                      </h4>
                    </li>
                    <li>
                      <h4 className="h6 mb-0">• Cadência e tempos de apoio</h4>
                    </li>
                    <li>
                      <h4 className="h6 mb-0">• Balanço durante a marcha</h4>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pr-lg-0">
              <div className="poligonal h-100 w-100">
                <div className="h-100 w-100" />
              </div>
            </div>
          </div>
        </div>
      </BgGray>
      <Container>
        <div className="container">
          <div className="row mt-5 mb-4">
            <div className="col-lg-6">
              <img src={correndo} alt="Grupo correndo" />
            </div>
            <div className="col-lg-6">
              <h2 className="h4 h1-md lh-4 font-weight-bold mb-4">
                AVALIAÇÃO POSTURAL: <br className="d-none d-md-block" />A
                POSTURA CORRETA <br className="d-none d-md-block" />
                EVITA LESÕES
              </h2>
              <p className="h5 h6-lg mb-3">
                Algumas pessoas pensam que uma das mais importantes aptidões
                musculares é a força, mas para nós, que estudamos a incidência
                de lesões nas modalidades esportivas e trabalhamos com programas
                de prevenção, é a destreza.
              </p>
              <p className="h5 h6-lg mb-3">
                A destreza é a capacidade dos músculos para adaptarem-se ao
                esforço da prática esportiva com um bom controle neuromuscular.
                Nesse sentido, o aparelho locomotor necessita estar adaptado ao
                esforço ao qual será submetido, através da postura adequada que
                a corrida de rua exige.
              </p>
              <p className="h5 h6-lg mb-3">
                Aqui na Physic realizamos o{' '}
                <span>
                  <u>Runner</u>
                </span>
                , um programa para prevenção de lesões na corrida e performance.
              </p>
            </div>
          </div>
        </div>
      </Container>
      <Equipamentos />
      <Contato />
    </>
  );
}

export default Biomecanica;
