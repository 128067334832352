import styled from 'styled-components';

let banner1;
if (window.innerWidth >= 768) {
  banner1 = require('~/assets/banners-home/joelho_desk.png');
} else {
  banner1 = require('~/assets/banners-home/joelho_mobile.webp');
}

let banner2;
if (window.innerWidth >= 768) {
  banner2 = require('~/assets/banners-home/h5_desk.png');
} else {
  banner2 = require('~/assets/banners-home/h5_mobile.webp');
}

let banner3;
if (window.innerWidth >= 768) {
  banner3 = require('~/assets/banners-home/pilates_desk.png');
} else {
  banner3 = require('~/assets/banners-home/pilates_mobile.webp');
}

let banner4;
if (window.innerWidth >= 768) {
  banner4 = require('~/assets/banners-home/equipamentos_desk.png');
} else {
  banner4 = require('~/assets/banners-home/equipamentos_mobile.webp');
}

export const Container = styled.div`
  span::after {
    color: transparent;
    -webkit-text-stroke: 1px rgba(167, 171, 173, 0.52);
    display: block;
    content: attr(data-texto);
    z-index: -1;
    position: absolute;
    bottom: 3px;
    left: 3px;
    width: 99%;
    font-style: italic;
  }

  p,
  h1,
  h4,
  ul li {
    color: #333;
    text-decoration: none;
  }

  .btn-veja {
    color: #b5295e;
    border: solid 1px #b5295e;
    border-radius: 20px;
  }

  .color {
    color: #333 !important;
  }

  .color-qualidade {
    color: #c32027;
  }

  .color-medicina {
    color: #841619;
  }

  .letras-borda {
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #333;
    font-weight: bold;
  }

  .bg-gray {
    background-color: #e7e7e8;
  }

  .bg-light-gray {
    background-color: #edeeef;
    z-index: 0;
    position: relative;
  }
  .bg-red {
    background-color: #b7274e;
  }
  .mg-title {
    margin-top: 135px;
  }
  .btn-banner {
    border: solid 1px #fff;
    padding: 8px 26px;
    border-radius: 23px;
  }

  .banner1 {
    background-size: cover;
    background-image: url(${banner1});
    background-position: center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
  }

  .banner2 {
    background-size: cover;
    background-image: url(${banner2});
    background-position: center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
  }

  .banner3 {
    background-size: cover;
    background-image: url(${banner3});
    background-position: center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
  }

  .banner4 {
    background-size: cover;
    background-image: url(${banner4});
    background-position: center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
  }

  .btn-details {
    background-color: gray;
    color: #fff !important;
  }

  .rec.rec-arrow {
    position: absolute;
    z-index: 1000;
  }

  .rec.rec-arrow-left {
    left: 10px;
  }

  .rec.rec-arrow-right {
    right: 10px;
  }

  .rec-dot_active {
    background-color: #fff;
    box-shadow: 0 0 1px 2px #fff;
  }

  .rec-dot {
    box-shadow: 0 0 1px 2px #fff;
  }

  @media screen and (min-width: 1400px) {
    .mg-title {
      margin-top: 250px;
    }
  }

  @media screen and (min-width: 992px) {
    .carousel-mobile {
      .rec.rec-carousel-wrapper,
      .rec.rec-carousel {
        display: block;

        .rec.rec-slider {
          position: relative;

          .rec.rec-swipable {
            flex-wrap: wrap;

            .rec.rec-carousel-item {
              padding: 0 15px;
              padding: 0 15px;
              width: 33.333%;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .banner1 {
      height: 624px !important;
    }

    .banner2 {
      height: 624px !important;
    }

    .banner3 {
      height: 624px !important;
    }

    .banner4 {
      height: 624px !important;
    }

    .mg-title {
      margin-top: 200px;
    }
  }

  @media screen and (max-width: 768px) {
    .mg-title {
      margin-top: 250px;
    }
  }

  @media screen and (max-width: 568px) {
    .mg-title {
      margin-top: 300px;
    }

    .mg-h5 {
      margin-top: 250px;
    }
  }

  @media (min-width: 462px) and (max-width: 528px) {
    span::after {
      padding: 0 20px;
    }
  }
`;
