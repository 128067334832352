import React from 'react';
import { Helmet } from 'react-helmet';

import Equipamentos from '~/components/Equipamentos';
import Contato from '~/components/Contato';

import expertise from '~/assets/icones/somos-expertise.svg';
import h5 from '~/assets/icones/somos-h5.svg';
import medicina from '~/assets/icones/somos-medicina.svg';
import tratamento from '~/assets/icones/somos-tratamentos.svg';
import prevencao from '~/assets/icones/somos-prevencao.svg';
import qualidade from '~/assets/icones/somos-qualidade.svg';

import murilo from '~/assets/metodos/somos-murilo.png';
import muriloMobile from '~/assets/metodos/murilo-mobile.png';
import rafael from '~/assets/metodos/somos-rafael.png';
import rafaelMobile from '~/assets/metodos/rafael-mobile.png';
import amanda from '~/assets/metodos/somos-ana-claudia.png';
import amandaMobile from '~/assets/metodos/somos-ana-claudia-mobile.png';
import andre from '~/assets/metodos/somos-andre.png';
import andreMobile from '~/assets/metodos/andre-mobile.png';
import bruno from '~/assets/metodos/somos-bruno.png';
import brunoMobile from '~/assets/metodos/bruno-mobile.png';
import joao from '~/assets/metodos/somos-joao.png';
import joaoMobile from '~/assets/metodos/joao-mobile.png';
import maira from '~/assets/metodos/somos-ana-carolina.png';
import mairaMobile from '~/assets/metodos/somos-ana-carolina-mobile.png';
import sarah from '~/assets/metodos/somos-bruno-foschi.png';
import sarahMobile from '~/assets/metodos/somos-bruno-foschi-mobile.png';
import ticiane from '~/assets/metodos/ticiane-cordeiro.png';
import ticianeMobile from '~/assets/metodos/ticiane-mobile.png';
import felipe from '~/assets/metodos/somos-isabella-cristina.png';
import felipeMobile from '~/assets/metodos/somos-isabella-cristina-mobile.png';

import gabriel from '~/assets/metodos/somos-gabriel-pereira.png';
import gabrielMobile from '~/assets/metodos/somos-gabriel-pereira-mobile.png';

import leandro from '~/assets/metodos/somos-leandro-damacena.png';
import leandroMobile from '~/assets/metodos/somos-leandro-damacena-mobile.png';

import vitor from '~/assets/metodos/somos-vitor-prado.png';
import vitorMobile from '~/assets/metodos/somos-vitor-prado-mobile.png';

import { Container, BgGray, Equipe } from './styles';

function QuemSomos() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – Quem Somos</title>
          <meta
            name="description"
            content="Clínica de alto padrão em Medicina, Fisioterapia e Treinamento Funcional. Localizada em frente ao parque Ibirapuera em São Paulo."
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  SOMOS A CLÍNICA PHYSIC!
                </h1>
                <h2 className="text-white  text-center h4 bold normal">
                  Alto padrão com tratamentos personalizados
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 my-5 text-center text-lg-left">
              <p className="h5 h6-md">
                Clínica Physic foi idealizada para ser uma clínica de ponta com
                o objetivo de oferecer aos pacientes e clientes um ambiente
                multifuncional e que cuidasse do corpo por completo, atendendo
                todas as necessidades em desenvolver a performance do corpo,
                alívio de dores, recuperar funcionalidades, prevenir lesões e
                cuidar do bem-estar de cada pessoa de forma única.
              </p>
              <p className="h5 h6-md mt-4">
                Possui consultórios com o mais alto padrão em atendimento e
                equipamentos de última geração que atendem a diversas áreas da
                saúde, com uma equipe de alta expertise especializada com
                Médicos, Fisioterapeutas, Nutricionistas, Psicólogos e
                Educadores Físicos.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h2 className="display-lg-4 font-weight-bold titulo text-center">
                Nossos <br />
                <span className="h1 display-lg-3 font-weight-bold">
                  DIFERENCIAIS:
                </span>
              </h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-9 d-flex flex-wrap">
              <div className="col-6 col-md-4 col-lg-4 text-center">
                <img
                  src={expertise}
                  alt="Profissionais de alta expertise"
                  className="pt-4"
                />
                <h3 className="mt-3 mt-lg-2 h6 pt-1">
                  Profissionais de alta expertise{' '}
                </h3>
              </div>
              <div className="col-6 col-md-4 col-lg-4 text-center">
                <img
                  src={h5}
                  alt="Método exclusivo Physic"
                  className="w-lg-50 w-xxl-40 pt-2 pt-sm-4 pt-md-3 pt-xl-2"
                />
                <h3 className="h6 mt-2">
                  H5 Health – método exclusivo&nbsp;Physic{' '}
                </h3>
              </div>
              <div className="col-6 col-md-4 col-lg-4 text-center">
                <img
                  src={medicina}
                  alt="Medicina e Fisioterapia"
                  className="pt-3"
                />
                <h3 className="h6 mt-2">Medicina e Fisioterapia </h3>
              </div>

              <div className="col-6 col-md-4 col-lg-4 text-center">
                <img
                  src={tratamento}
                  alt="Profissionais de alta expertise"
                  className="pt-3"
                />
                <h3 className="h6 mt-2">
                  Tratamentos personalizados e individuais{' '}
                </h3>
              </div>
              <div className="col-6 col-md-4 col-lg-4 text-center">
                <img src={prevencao} alt="Método exclusivo Physic" />
                <h3 className="h6 mt-2">
                  Orientação personalizada para prevenção de lesões
                </h3>
              </div>
              <div className="col-6 col-md-4 col-lg-4 text-center">
                <img
                  src={qualidade}
                  alt="Medicina e Fisioterapia"
                  className=""
                />
                <h3 className="h6 mt-2">
                  Programas para performance e qualidade de vida
                </h3>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <BgGray>
        <div className="container">
          <div className="row justify-content-center py-5">
            <div className="col-lg-8 text-center">
              <h2 className="display-lg-4 titulo font-weight-bold">
                Nossos <br />
                <span className="h1 display-lg-3 font-weight-bold">
                  PROFISSIONAIS
                </span>
              </h2>
              <h3 className="display-lg-4 titulo font-weight-bold mb-4">
                Nossos criadores
              </h3>
            </div>
            <div className="col-lg-6 d-lg-flex">
              <div className="col-lg-6 px-lg-0">
                <img
                  src={murilo}
                  alt="Dr. Murilo Curtolo"
                  className="d-none d-lg-block"
                />
                <img
                  src={muriloMobile}
                  alt="Dr. Murilo Curtolo"
                  className="d-block d-lg-none pb-4 mx-auto w-100"
                />
              </div>
              <div className="col-lg-6">
                <h3 className="h5 font-weight-bold">DR. MURILO CURTOLO</h3>
                <p>
                  Fisioterapeuta e Co-fundador da clínica Physic | Crefito 3 -
                  132915-F
                </p>
                <ul>
                  <li>
                    • Fisioterapeuta graduado pela Universidade Federal de São
                    Paulo – UNIFESP (2009);
                  </li>
                  <li>
                    • Especialista em Fisioterapia no Esporte pelo Centro de
                    Traumato- Ortopedia no Esporte (CETE/UNIFESP) (2010);
                  </li>
                  <li>• Mestre em Ciências da Saúde pela UNIFESP;</li>
                  <li>
                    • Membro da Sociedade Nacional de Fisioterapia Esportiva
                    (SONAFE) (2016);
                  </li>
                  <li>
                    • Especialista em análise biomecânica na corrida,
                    trabalhando na prevenção de lesões de corredores amadores e
                    profissionais desde 2013;
                  </li>
                  <li>
                    • Além disso possui formação complementar em cursos de
                    Terapia Manual, Kinesio-Taping, Mobilização Neural e
                    Pilates.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 d-lg-flex">
              <div className="col-lg-6 px-lg-0">
                <img
                  src={rafael}
                  alt="Dr. Rafael G.Tambuque"
                  className="d-none d-lg-block"
                />
                <img
                  src={rafaelMobile}
                  alt="Dr. Rafael G.Tambuque"
                  className="d-block d-lg-none pb-4 mx-auto w-100"
                />
              </div>
              <div className="col-lg-6">
                <h3 className="h5 font-weight-bold">DR. RAFAEL G. TAMBUQUE</h3>
                <p>
                  Fisioterapeuta e Co-fundador da clínica Physic | Crefito 3 -
                  198490-F
                </p>
                <ul>
                  <li>
                    • Fisioterapeuta graduado pela Universidade Santa Cecília –
                    UNISANTA (2012);
                  </li>
                  <li>
                    • Pós-graduado em Ortopedia e Traumatologia Ambulatorial e
                    Hospitalar pela Universidade de Campinas – UNICAMP (2013);
                  </li>
                  <li>
                    • Além disso possui formação complementar em cursos de Dry
                    Needling, Manipulação Manual e Pilates;
                  </li>
                  <li>
                    • Nos últimos anos teve vasta experiência com pacientes de
                    traumas ortopédicos, lesões osteomioarticulares,
                    pós-operatório ortopédico, atuando também na prevenção de
                    lesões de atletas amadores e profissionais.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </BgGray>
      <Equipe>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-5">
              <h3 className="display-4 display-lg-3 font-weight-bold pt-5 sombra mg-borda">
                <span data-texto="EQUIPE" className="absolute-mobile">
                  EQUIPE
                </span>
              </h3>
            </div>
          </div>
          <div className="row justify-content-center pt-5 pt-lg-0">
            <div className="col-lg-6 d-lg-flex">
              <div className="col-lg-6 px-lg-0">
                <img
                  src={maira}
                  alt="Dra. Maira Sobreiro"
                  className="d-none d-lg-block"
                />
                <img
                  src={mairaMobile}
                  alt="Dra. Maira Sobreiro"
                  className="d-block d-lg-none pb-4 mx-auto w-100"
                />
              </div>
              <div className="col-lg-6">
                <h3 className="h5 font-weight-bold">DRA. ANA CAROLINA MELLO DE ARAUJO</h3>
                <p>CREFITO 3 - 320543-F</p>
                <ul>
                  <li>
                    • Graduação em Fisioterapia pela UnG universidade de Guarulhos (2020);
                  </li>
                  <li>
                    • Aprimoramento em Fisioterapia Esportiva pelo Centro de Traumatologia do Esporte CETE (2022);
                  </li>
                  <li>
                    • Experiência em eventos esportivos;
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 d-lg-flex">
              <div className="col-lg-6 px-lg-0">
                <img
                  src={amanda}
                  alt="Dra. Amanda Chimura"
                  className="d-none d-lg-block"
                />
                <img
                  src={amandaMobile}
                  alt="Dra. Amanda Chimura"
                  className="d-block d-lg-none pb-4 mx-auto w-100"
                />
              </div>
              <div className="col-lg-6">
                <h3 className="h5 font-weight-bold">DRA. ANA CLÁUDIA KLINKEDRA.</h3>
                <p>CREFITO 205728-F</p>
                <ul>
                  <li>
                    • Graduação em Fisioterapia pela Universidade Metodista de Piracicaba (2014);
                  </li>

                  <li>• Formação completa do método Pilates;</li>
                  <li>• Osteopatia estrutural e visceral;</li>
                  <li>• Terapia Instrumental Quiropráxica;</li>
                  <li>• Bandagem elástica funcional;</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 d-lg-flex mt-lg-4 mt-xxl-5">
              <div className="col-lg-6 px-lg-0">
                <img
                  src={sarah}
                  alt="Dra. Sarah Tinini"
                  className="d-none d-lg-block"
                />
                <img
                  src={sarahMobile}
                  alt="Dra. Sarah Tinini"
                  className="d-block d-lg-none pb-4 mx-auto w-100"
                />
              </div>
              <div className="col-lg-6">
                <h3 className="h5 font-weight-bold">DR. BRUNO FOSCHI</h3>
                <p>CREFITO 3 - 159898-F</p>
                <ul>
                  <li>
                    • Formado na Pontifícia Universidade Católica de Campinas - PUC Campinas (2010);
                  </li>
                  <li>
                    • Especialização: Reabilitação Aplicada a Neurologia Infantil pela EXTECAMP - Cursos de Extensão da Unicamp (2011);
                  </li>
                  <li>
                    • Conceito Maitland: Grupo Terapia Manual - Campinas (2013);
                  </li>

                  <li>
                    • Pilates: CAF - Cursos de Fisioterapia (2012);
                  </li>
                  <li>
                    • Ergonomia aplicada: Teoria e Prática - Grupo Posturar/SP (2012);
                  </li>
                  <li>
                    •  Trabalhou como estagiário no Ituano Futebol Clube (2010).
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 d-lg-flex mt-lg-5 mt-xxl-5">
              <div className="col-lg-6 px-lg-0">
                <img
                  src={bruno}
                  alt="Dr. Bruno Fillet"
                  className="d-none d-lg-block"
                />
                <img
                  src={brunoMobile}
                  alt="Dr. Bruno Fillet"
                  className="d-block d-lg-none pb-4 mx-auto w-100"
                />
              </div>
              <div className="col-lg-6">
                <h3 className="h5 font-weight-bold">DR. BRUNO FILLET</h3>
                <p>CREFITO 107721-F</p>
                <ul>
                  <li>
                    • Graduado em Fisioterapia pela Universidade Metodista de
                    Piracicaba UNIMEP;
                  </li>
                  <li>
                    • Pós-Graduado em Acupuntura pelo Instituto Brasileiro de
                    Acupuntura IBRAM;
                  </li>
                  <li>
                    • Pós-Graduado em Terapia Manual e Postural pela Escola de
                    Terapia Manual e Postural Salgado;
                  </li>
                  <li>
                    • Pós-graduado em Ozonioterapia - ABOZ (Associação
                    Brasileira de Ozonioterapia);
                  </li>
                  <li>
                    • Aperfeiçoamento em Analgesia e Anestesia pela Acupuntura
                    (Acupuntura Avançada),
                  </li>
                  <li>
                    • Ginecologia e Obstetrícia pela acupuntura e
                    Auriculoterapia Avançada - WFCMS - Beijing/ Tian Jin - China
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 d-lg-flex mt-lg-5 mt-xxl-5">
              <div className="col-lg-6 px-lg-0">
                <img
                  src={joao}
                  alt="Dr. João Paulo"
                  className="d-none d-lg-block"
                />
                <img
                  src={joaoMobile}
                  alt="Dr. João Paulo"
                  className="d-block d-lg-none pb-4 mx-auto w-100"
                />
              </div>
              <div className="col-lg-6">
                <h3 className="h5 font-weight-bold">DR. JOÃO PAULO</h3>
                <p>CRM 165876 - SP</p>
                <ul>
                  <li>• Atua como Médico Ortopedista na Physic;</li>
                  <li>
                    • Graduado em Medicina pela Universidade Federal do Rio
                    Grande do Norte (UFRN);
                  </li>
                  <li>
                    • Realizou residência de Ortopedia e Traumatologia no
                    Hospital Federal da Lagoa (RJ);
                  </li>
                  <li>
                    • Em São Paulo, fez especialização em Cirurgia do Joelho e
                    Traumatologia Esportiva;
                  </li>
                  <li>
                    • Realiza Pós-Graduação em nível de Mestrado pela
                    Universidade de São Paulo (USP), com foco no uso de
                    células-tronco para o tratamento de lesões nos joelhos;
                  </li>
                  <li>
                    • É afiliado a diversas Sociedades Médicas, como: Sociedade
                    Brasileira de Ortopedia (SBOT) e Sociedade Brasileira de
                    Cirurgia do Joelho (SBCJ).
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 d-lg-flex mt-lg-4 mt-xxl-5">
              <div className="col-lg-6 px-lg-0">
                <img
                  src={andre}
                  alt="Dr. André Sugano"
                  className="d-none d-lg-block"
                />
                <img
                  src={andreMobile}
                  alt="Dr. André Sugano"
                  className="d-block d-lg-none pb-4 mx-auto w-100"
                />
              </div>
              <div className="col-lg-6">
                <h3 className="h5 font-weight-bold">DR. ANDRÉ SUGANO</h3>
                <p>CREFITO 222655-F</p>
                <ul>
                  <li>
                    • Graduado em Fisioterapia pelo Centro Universitário São
                    Camilo;
                  </li>
                  <li>
                    • Graduado em Educação Física pela Faculdades Metropolitanas
                    Unidas (FMU);
                  </li>
                  <li>
                    • Pós-Graduado em Fisiologia do Exercício pelo Centro de
                    Estudos em Fisiologia do Exercício da Universidade Federal
                    de São Paulo (CEFE/UNIFESP);
                  </li>
                  <li>
                    • Pós-Graduado em Fisioterapia no Esporte pelo Centro de
                    Traumato- Ortopedia do Esporte da Universidade Federal de
                    São Paulo (CETE/UNIFESP);
                  </li>
                  <li>• Formação em Quiropraxia e Maitland</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 d-lg-flex mt-lg-4 mb-lg-4 mt-xxl-5">
              <div className="col-lg-6 px-lg-0">
                <img
                  src={ticiane}
                  alt="Dra. Ticiane Cordeiro Roim"
                  className="d-none d-lg-block"
                />
                <img
                  src={ticianeMobile}
                  alt="Dra. Ticiane Cordeiro Roim"
                  className="d-block d-lg-none pb-4 mx-auto w-100"
                />
              </div>
              <div className="col-lg-6">
                <h3 className="h5 font-weight-bold">
                  Dra. Ticiane Cordeiro Roim
                </h3>
                <p>CRM: 150536 - SP</p>
                <ul>
                  <li>
                    • Formada em Medicina pela Universidade Federal de São Paulo
                    UNIFESP-EPM em 2011;
                  </li>
                  <li>
                    • Residência Médica em Ortopedia e Traumatologia (de 2012 a
                    2014) com especialização em Medicina do Pé e Tornozelo (em
                    2015) pela mesma instituição;
                  </li>
                  <li>
                    • Pós graduanda no curso de Tratamento Intervencionista da
                    Dor no Hospital Israelita Albert Einstein (início em
                    Setembro de 2020);
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 d-lg-flex mt-lg-4 mb-lg-4 mt-xxl-5">
              <div className="col-lg-6 px-lg-0">
                <img
                  src={felipe}
                  alt="Dr. Felipe Guilherme Marcondes Aparecido"
                  className="d-none d-lg-block"
                />
                <img
                  src={felipeMobile}
                  alt="Dr. Felipe Guilherme Marcondes Aparecido"
                  className="d-block d-lg-none pb-4 mx-auto w-100"
                />
              </div>
              <div className="col-lg-6">
                <h3 className="h5 font-weight-bold">
                  DRA. ISABELLA CRISTINA BARBOZA ALMEIDA MARQUES
                </h3>
                <p>CREFITO 3 - 298092-F</p>
                <ul>
                  <li>
                    • Graduação em Fisioterapia, Universidade Cidade de São Paulo (2019);
                  </li>
                  <li>
                    • Especialização em Fisioterapia Esportiva, Universidade Federal de São Paulo (2021);
                  </li>
                  <li>
                    • Aprimoramento em Fisioterapia Esportiva, Centro de Traumatologia do Esporte (2021);
                  </li>
                  <li>
                    • Avaliação da biomecânica de praticantes de corrida (2022).
                  </li>
                </ul>
              </div>
            </div>

            
            <div className="col-lg-6 d-lg-flex mt-lg-4 mb-lg-4 mt-xxl-5">
              <div className="col-lg-6 px-lg-0">
                <img
                  src={gabriel}
                  alt="Dr. Felipe Guilherme Marcondes Aparecido"
                  className="d-none d-lg-block"
                />
                <img
                  src={gabrielMobile}
                  alt="Dr. Felipe Guilherme Marcondes Aparecido"
                  className="d-block d-lg-none pb-4 mx-auto w-100"
                />
              </div>
              <div className="col-lg-6">
                <h3 className="h5 font-weight-bold">
                  DR. GABRIEL PEREIRA DE CARVALHO
                </h3>
                <p>CREFITO 3 - 254987F</p>
                <ul>
                  <li>
                    • Graduação em Fisioterapia pelo Centro Universitário São Camilo (2017);
                  </li>
                  <li>
                    • Especialista em fisioterapia musculoesquelética pela Faculdade de Ciências Médicas da Santa Casa de SP (2019);
                  </li>
                  <li>
                    • Formação no conceito maitland;
                  </li>
                  <li>
                    • Bandagem rígida;
                  </li>
                  <li>
                    • Atualização em eletroterapia;
                  </li>
                  <li>
                    • Mobilização neural;
                  </li>
                  <li>
                    • Novos conceitos em reabilitação das lesões de joelho e quadril;
                  </li>
                  <li>
                    • Novos conceitos em reabilitação de ombro;
                  </li>
                  <li>
                    • Formação no conceito mulligan;
                  </li>
                </ul>
              </div>
            </div>

            
            <div className="col-lg-6 d-lg-flex mt-lg-4 mb-lg-4 mt-xxl-5">
              <div className="col-lg-6 px-lg-0">
                <img
                  src={leandro}
                  alt="Dr. Felipe Guilherme Marcondes Aparecido"
                  className="d-none d-lg-block"
                />
                <img
                  src={leandroMobile}
                  alt="Dr. Felipe Guilherme Marcondes Aparecido"
                  className="d-block d-lg-none pb-4 mx-auto w-100"
                />
              </div>
              <div className="col-lg-6">
                <h3 className="h5 font-weight-bold">
                DR. LEANDRO DAMACENO DO NASCIMENTO
                </h3>
                <p>CREFITO 3 - 266956-F</p>
                <ul>
                  <li>
                    • Graduação em Fisioterapia, Faculdades Metropolitanas Unidas (2018);
                  </li>
                  <li>
                    • Especialização em Fisioterapia Traumato - Ortopedia e Esportiva, Faculdade inspirar (2021);
                  </li>
                  <li>
                    • Treinamento e Reabilitação Funcional, André melo Loft (2019);
                  </li>
                  <li>
                    • Terapia manual aplicada em lesões musculoesqueléticas, Club Fisio (2019).
                  </li>
                </ul>
              </div>
            </div>

            
            <div className="col-lg-6 d-lg-flex mt-lg-4 mb-lg-4 mt-xxl-5">
              <div className="col-lg-6 px-lg-0">
                <img
                  src={vitor}
                  alt="Dr. Felipe Guilherme Marcondes Aparecido"
                  className="d-none d-lg-block"
                />
                <img
                  src={vitorMobile}
                  alt="Dr. Felipe Guilherme Marcondes Aparecido"
                  className="d-block d-lg-none pb-4 mx-auto w-100"
                />
              </div>
              <div className="col-lg-6">
                <h3 className="h5 font-weight-bold">
                  DR. VITOR PRADO
                </h3>
                <p>CREFITO 3 - 322276F</p>
                <ul>
                  <li>
                    • Graduação em Fisioterapia pela Universidade Anhembi Morumbi (2020);
                  </li>
                  <li>
                    • Especializando em Ortopedia e Traumatologia pela Faculdade de Ciências Médicas Santa Casa de SP (2022);
                  </li>
                  <li>
                    • Biomecânica para prescrição clínica;
                  </li>
                  <li>
                    • Conceito liberação miofascial;
                  </li>
                  <li>
                    • Curso bandagens terapêuticas;
                  </li>
                  <li>
                    • Experiência em eventos esportivos;
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Equipe>
      <Equipamentos />
      <Contato />
    </>
  );
}

export default QuemSomos;
