import styled from 'styled-components';

import banner from '~/assets/banners-home/h5_desk.png';
import mobile from '~/assets/banners-home/h5_mobile.webp';
import bgLogo from '~/assets/icones/logo-bg.svg';
import bgLogoMaior from '~/assets/icones/logo-maior.svg';
import bgLogoMobile from '~/assets/icones/logo-mobile.svg';
import sergio from '~/assets/metodos/sergio.png';
import hildebrando from '~/assets/metodos/hildebrando.png';
import denis from '~/assets/metodos/denis.png';
import exercicio from '~/assets/metodos/mulher-exercicio.png';

export const Container = styled.div`
  p,
  ul li {
    color: #333;
  }

  p {
    span {
      color: #841619;
    }
  }

  ul li {
    line-height: 2rem;
  }

  h2 {
    color: #841619;
  }

  h3,
  h5 {
    color: #333;
  }

  h2 {
    span {
      color: transparent;
      -webkit-text-stroke: 1px #333;
    }
  }

  .color {
    color: #841619 !important;
    -webkit-text-stroke: 0px;
  }
  .sombra {
    color: #841619;
    span::after {
      color: transparent;
      -webkit-text-stroke: 1px rgba(167, 171, 173, 0.52);
      display: block;
      content: attr(data-texto);
      z-index: -1;
      position: absolute;
      bottom: 4px;
      left: 63px;
    }
  }

  .bg-gray {
    background-color: #e7e7e8;
  }

  .banner {
    background-image: url(${banner});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
    margin-top: 31px;
  }

  .bg-logo {
    background-image: url(${bgLogo});
    background-color: #e7e7e8;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
  }

  .bg-logo-grande {
    background-image: url(${bgLogoMaior});
    background-color: #e7e7e8;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
  }

  .bg-exercicio {
    background-image: url(${exercicio});
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    padding: 4% 0 22% 0;
  }

  .mg-top {
    margin-top: 150px;
  }

  .borda {
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #333;
    font-weight: bold;
    font-size: 6rem;
  }

  @media screen and (min-width: 1400px) {
    .sombra {
      span::after {
        left: 93px;
      }
    }

    .mg-top {
      margin-top: 250px;
    }
  }

  @media screen and (max-width: 1200px) {
    .borda {
      font-size: 4rem;
    }

    .sombra {
      span::after {
        left: 31px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .sombra {
      span::after {
        left: 18px;
      }
    }
    .bg-gray {
      background-color: #fff;
    }

    .w-94 {
      width: 94% !important;
    }
  }

  @media screen and (min-width: 991px) {
    .lh-4 {
      line-height: 4rem;
    }
  }

  @media screen and (max-width: 991px) {
    .lh-1 {
      line-height: 1.9rem !important;
    }

    .lh-3 {
      line-height: 3rem !important;
    }

    .slick-dots li button:before {
      font-size: 15px;
      color: #70707080;
    }

    .slick-dots li.slick-active button:before {
      color: #707070;
    }
  }

  @media screen and (max-width: 768px) {
    h5 {
      font-size: 3vw !important;
    }
    .banner {
      background-image: url(${mobile});
      background-position: center right;
    }

    .bg-logo-grande {
      background-image: url(${bgLogoMobile});
    }

    .mg-top {
      margin-top: 250px;
    }

    .borda {
      font-size: 12vw;
    }

    .sombra {
      span::after {
        bottom: 4px;
        left: 11px;
      }
    }
  }
`;

export const Hildebrando = styled.div`
  background-color: #e7e7e8;
  z-index: 0;
  position: relative;

  ul li {
    color: #333;
    margin-bottom: 1rem;
  }

  h2,
  h3 {
    color: #841619;
  }

  p {
    color: #333;
  }

  .padding-left {
    padding-left: 20%;
  }

  .poligonal {
    clip-path: polygon(100% 100%, 20% 100%, 0% 0%, 100% 0%);
    background: #fff;
    height: 813px !important;
    div {
      background-image: url(${hildebrando});
      background-position: top center;
      background-size: cover;
      clip-path: polygon(100% 100%, 22% 100%, 2% 0%, 100% 0%);
      height: 813px !important;
    }
  }
`;

export const Sergio = styled.div`
  background-color: #e7e7e8;
  z-index: 0;
  position: relative;

  ul li {
    color: #333;
    margin-bottom: 1rem;
  }

  p {
    color: #333;
  }

  h2,
  h3,
  h4 {
    color: #841619;
  }

  .padding-left {
    padding-left: 20%;
  }

  .poligonal {
    clip-path: polygon(88% 100%, 0% 100%, 0% 0%, 98% 0%);
    background: #fff;
    div {
      background-image: url(${sergio});
      background-position: top center;
      background-size: cover;
      clip-path: polygon(86% 100%, 0% 100%, 0% 0%, 96% 0%);
    }
  }
`;

export const Denis = styled.div`
  background-color: #e7e7e8;
  z-index: 0;
  position: relative;

  ul li {
    color: #333;
    margin-bottom: 1rem;
  }

  p {
    color: #333;
  }

  h2,
  h3,
  h4 {
    color: #841619;
  }

  .padding-left {
    padding-left: 20%;
  }

  .poligonal {
    clip-path: polygon(88% 100%, 0% 100%, 0% 0%, 98% 0%);
    background: #fff;
    height: 813px !important;

    div {
      background-image: url(${denis});
      background-position: top center;
      background-size: cover;
      clip-path: polygon(86% 100%, 0% 100%, 0% 0%, 96% 0%);
      height: 813px !important;
    }
  }
`;
