import React from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';
import Equipamentos from '~/components/Equipamentos';
import Contato from '~/components/Contato';

import { Container } from './styles';

import voltar from '~/assets/icones/icone-voltar.svg';
import tenis from '~/assets/metodos/tenis.png';

function Acupuntura() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic –Acupuntura</title>
          <meta
            name="description"
            content="Clínica Physic: técnica milenar de acupuntura para obter efeitos terapêuticos. Atendimento Omint. Localizada no Ibirapuera."
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  ACUPUNTURA
                </h1>
                <h2 className="h5 h4-md my-5 my-lg-0 text-white font-italic text-center font-weight-bold">
                  Medicina tradicional chinesa
                </h2>
                <div className="text-center mt-lg-4">
                  <Link
                    to={`${process.env.PUBLIC_URL}/especialidades`}
                    className="text-white h4"
                  >
                    <img src={voltar} alt="Icone voltar" className="mr-2" />
                    Voltar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center my-4">
              <h2 className="h4 h2-md font-weight-bold">
                A TÉCNICA MILENAR POSSUI EFEITOS TERAPÊUTICOS PARA O CORPO E A
                MENTE
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 d-flex align-items-center order-1 order-lg-0">
              <div>
                <p className="h5 h6-lg my-3">
                  Aqui na Physic, a acupuntura é utilizada para o tratamento de
                  dores crônicas e agudas de diversas origens, para controle da
                  dor durante alguns procedimentos cirúrgicos, prevenção e
                  tratamento de doenças, dores musculoesqueléticas e
                  normalização funcional do organismo.
                </p>
                <p className="h5 h6-lg my-3">
                  Além disso, a técnica também é utilizada por atletas,
                  profissionais e amadores para modular o bem-estar físico, para
                  o alcance de um nível mais alto de performance competitiva e
                  para um melhor treinamento.
                </p>
              </div>
            </div>
            <div className="col-lg-8 order-0 order-lg-1">
              <img src={tenis} alt="Mulher jogando tenis" />
            </div>
          </div>
        </div>
      </Container>
      <Equipamentos />
      <Contato />
    </>
  );
}

export default Acupuntura;
