import styled, { css } from 'styled-components';
import { shade, darken, lighten } from 'polished';

export const Container = styled.header`
  .bg-btn {
    background-color: #d01519;
    color: #fff;
    border-radius: 20px;
    :hover {
      color: ${lighten(0.1, '#fff')};
      background-color: ${darken(0.1, '#D01519')};
      text-decoration: none;
    }
  }
  .bg-btn-gray {
    background-color: #67666D;
    color: #fff;
    border-radius: 20px;
    :hover {
      color: ${lighten(0.1, '#fff')};
      background-color: ${darken(0.1, '#67666D')};
      text-decoration: none;
    }
  }
  .color-whats {
    padding: 4px;
    color: #fff;
    background-color: #00bfa5;
    border-radius: 20px;
  }

  height: 90px;

  > div {
    .menu-btn {
      border: none;
      cursor: pointer;
      &:active,
      &:focus {
        outline: none;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 8px;
        left: 12px;
        background: #333;
        border-radius: 50%;
        width: 65px;
        height: 65px;
        transition: all 0.2s;
        opacity: 0;
      }
      &__icon {
        position: relative;
        width: 50px;
        height: 40px;
        margin: 0 auto;
        cursor: pointer;
      }
    }

    .icon {
      position: absolute;
      top: 0;
      left: 0;
    }

    .icon--menu {
      span {
        display: block;
        width: 50px;
        height: 4px;
        background: #c32027;
        transition: all 0.18s;
        transition-timing-function: $transition;
        transition-delay: 0.05s;
        &:not(last-child) {
          margin-bottom: 12px;
        }
      }
    }

    .icon--close {
      transform: scale(0);
      transform-origin: center center;
      transition: all 0.18s;
      transition-delay: 0.08s;
      transition-timing-function: cubic-bezier(0.17, 0.67, 0.42, 1.36);
      opacity: 0;

      span {
        position: relative;
        display: block;
        width: 50px;
        height: 4px;
        background: #c32027;
        &:nth-of-type(1) {
          top: 17px;
          transform: rotate(45deg);
        }
        &:nth-of-type(2) {
          top: 13px;
          transform: rotate(-45deg);
        }
      }
    }

    .is--active {
      .icon--menu {
        span {
          width: 0;
        }
      }
      .icon--close {
        transform: scale(1);
        opacity: 1;
      }
    }

    .is--clicked {
      &:after {
        animation: btnClick 0.35s;
        animation-timing-function: cubic-bezier(0.17, 0.67, 0.42, 1.36);
      }
    }

    @keyframes btnClick {
      0% {
        opacity: 0;
        transform: scale(0);
      }
      50% {
        opacity: 0.35;
        transform: scale(1);
      }
      100% {
        opacity: 0;
        transform: scale(0);
      }
    }

    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }

    .slide-in-left {
      -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
      animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    @-webkit-keyframes slide-in-left {
      0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
      }
    }

    @keyframes slide-in-left {
      0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
      }
    }

    .slide-out-left {
      -webkit-animation: slide-out-left 0.5s
        cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
      animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    }

    @-webkit-keyframes slide-out-left {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
      }
      100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
      }
    }

    @keyframes slide-out-left {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
      }
      100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
      }
    }

    img {
      max-width: none;
    }
  }

  @media screen and (max-width: 991px) {
    height: 60px;

    .hide-mobile {
      display: none;
    }

    .size-mobile {
      font-size: 0.95rem;
    }
  }

  @media screen and (max-width: 400px) {
    .size-mobile {
      font-size: 3.6vw;
    }
  }
`;

export const MenuButton = styled.button`
  width: 40px;
  height: 40px;

  span {
    width: 100%;
    height: 5px;
    border-radius: 50px;
    display: block;
    background-color: #c32027;
    transition-duration: 0.3s;

    :nth-child(1) {
      width: 100%;
      height: 8px;
    }
    :nth-child(2) {
      margin: 5px 0;
      width: 60%;
      height: 8px;
      margin-left: 16px;
    }
  }

  ${props =>
    props.active &&
    css`
      span {
        :nth-child(1) {
          transform: rotate(45deg);
          width: 100%;
        }

        :nth-child(2) {
          transform: rotate(-45deg);
          margin: -8px 0;
          width: 100%;
        }
      }
    `}

  :focus {
    outline: none;
  }
`;

export const Menu = styled.div`
  li {
    a,
    button {
      color: #707070;
      transition-duration: 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;

      :hover {
        color: ${(0.5, '#D01519')};
        text-decoration: none;
      }
    }

    .active {
      color: ${(0.5, '#D01519')};
    }

    .drop-content {
      position: absolute;
      width: 100%;
      right: 0;
      border-radius: 15px;
      background-color: #333;
      ul li a {
        padding: 0 20%;
        color: #333;

        :hover {
          color: ${shade(0.5, '#333')};
        }
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    li {
      a {
        font-size: 0.7rem !important;
      }
    }
  }

  @media screen and (min-width: 992px) {
    ul {
      text-transform: uppercase;
    }
  }

  @media screen and (max-width: 991px) {
    background: #333;
    width: 75%;
    top: -5px;
    min-height: 101vh;
    transition-duration: 0.3s;
    right: ${props => (props.active ? '0' : '-1000px')};
    justify-content: flex-end;

    li {
      a,
      button {
        justify-content: flex-end;
        font-size: 1.25rem;
        font-weight: normal !important;
        color: #333;
        line-height: 40px;
      }
    }

    ul {
      border-radius: 40px 0 0 0;
      background-color: #fff;
      margin-bottom: 0px;
      height: 80vh;
    }
  }

  @media screen and (max-width: 400px) {
    li {
      a {
        line-height: 35px;
      }
    }
  }
`;
