import React from 'react';
import { Helmet } from 'react-helmet';

import { Container, BgGray, Beneficios, Fisioterapia } from './styles';
import Contato from '~/components/Contato';
import Equipamentos from '~/components/Equipamentos';

import massagem from '~/assets/metodos/atleta-massagem.png';
import massagem2 from '~/assets/metodos/atletas-massagem.png';
import runner from '~/assets/metodos/atletas-biomecanica.png';
import atleta from '~/assets/metodos/atletas-corrida.png';
import recovery from '~/assets/metodos/atletas-recovery.png';
import fisioterapia from '~/assets/metodos/atletas-fisioterapia.png';

function Atletas() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – Atletas</title>
          <meta
            name="description"
            content="Clínica Physic desenvolveu programas para atletas amadores e profissionais que desejam melhorar a performance e/ou evitar lesões."
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  ATLETAS
                </h1>
                <h2 className="h4 h2-md text-white text-center font-weight-bold">
                  Programas para atletas
                  <br />
                  profissionais e amadores
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5 pl-lg-5 pr-lg-0 text-center text-lg-left">
              <h2 className="h1 display-lg-3 font-weight-bold mg-borda">
                <span data-texto="RECOVERY" className="absolute-mobile">
                  RECOVERY
                </span>
              </h2>
              <p className="h5 h6-md pt-5 pt-lg-0 mt-5 mt-lg-0 mb-4 mb-lg-0">
                O programa Recovery busca a recuperação mais rápida pós-treinos
                intensos, mantendo o bom desempenho na corrida, restaurando a
                função do músculo e reparando danos teciduais (microtraumas).
                Além disso ajuda a diminuir a chance de dor e também a recuperar
                o desgaste psicológico do atleta.
              </p>
            </div>
            <div className="col-lg-7 pl-lg-0">
              <img src={massagem} alt="Atleta fazendo massagem" />
            </div>
          </div>
        </div>
      </Container>
      <Beneficios>
        <div className="container-fluid">
          <div className="row justify-content-center justify-content-lg-start">
            <div className="col-lg-6 pl-lg-0">
              <div className="poligonal h-100 w-100">
                <div className="h-100 w-100" />
              </div>
            </div>
            <div className="col col-sm-9 col-lg-4 col-xxl-3 py-5 py-lg-5">
              <h2 className="display-lg-4 font-weight-bold mb-4">
                Benefícios do
                <br />
                <span className="h1 display-lg-3 font-weight-bold">
                  PROGRAMA:
                </span>
              </h2>
              <ul className="pl-lg-5">
                <li>
                  <h3 className="h4 h3-md font-weight-bold">
                    • LIBERAÇÃO MUSCULAR
                  </h3>
                </li>
                <p className="h5 h6-md mb-5 mb-lg-2">
                  Soltura com hypervolt e rolo de massagem para alívio da tensão
                  muscular e melhora da circulação sanguínea.
                </p>
                <li>
                  <h3 className="h4 h3-md font-weight-bold">• CRIOTERAPIA</h3>
                </li>
                <p className="h5 h6-md mb-5 mb-lg-2">
                  Game Ready, equipamento de compressão e resfriamento na região
                  a ser tratada.
                </p>
                <li>
                  <h3 className="h4 h3-md font-weight-bold">
                    • BOTA COMPRESSIVA
                  </h3>
                </li>
                <p className="h5 h6-md">
                  Equipamento muito confortável que melhora a drenagem e auxilia
                  na eliminação de toxinas musculares. Sem contraindicação.
                </p>
              </ul>
            </div>
          </div>
        </div>
        <img src={massagem2} alt="Massagem" className="d-block d-lg-none" />
      </Beneficios>
      <Container>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5 pl-lg-4 pr-lg-0 text-center text-lg-left">
              <h2 className="h1 display-lg-3 font-weight-bold runner mg-runner">
                <span data-texto="RUNNER" className="absolute-mobile">
                  RUNNER
                </span>
              </h2>
              <p className="h5 h6-md pt-5 pt-lg-0 mt-5 mt-lg-0">
                Oferecemos ao atleta amador ou profissional a condição segura
                para o aperfeiçoamento da corrida, ativando e fortalecendo
                músculos específicos, prevenindo lesões, melhorando a
                performance e trazendo longevidade esportiva.
              </p>
              <p className="h5 h6-md pt-4 pt-lg-0 mt-3">
                O programa Runner Performance conta com a análise biomecânica do
                movimento, na qual identificamos os fatores de risco
                responsáveis pela sobrecarga na corrida, corrigindo
                posteriormente com nosso treinamento de correção postural na
                corrida.
              </p>
              <p className="h5 h6-md pt-4 pt-lg-0 mt-3">
                Juntamente com essa avaliação criteriosa e a prescrição de
                exercício personalizado progressivo respeitando os limites
                individuais, conseguimos alinhar quais são os pontos
                fundamentais para a prevenção de possíveis lesões no sistema
                musculo esquelético.
              </p>
            </div>
            <div className="col-lg-7 pl-lg-0">
              <img src={runner} alt="Atleta correndo" />
            </div>
          </div>
        </div>
      </Container>
      <BgGray>
        <div className="container">
          <div className="row align-items-center pt-5 pt-lg-0">
            <div className="col-lg-7 order-1 order-lg-0">
              <img src={atleta} alt="Atleta" />
            </div>
            <div className="col-lg-5 order-0 order-lg-1">
              <h2 className="display-lg-4 font-weight-bold mb-4 titulo">
                Benefícios do
                <br />
                <span className="h1 display-lg-3 font-weight-bold">
                  PROGRAMA:
                </span>
              </h2>
              <h3 className="h5 h6-md">
                Correção das alterações biomecânicas e posturais através de
                exercícios específicos para o atleta:{' '}
              </h3>
              <ul className="pl-3 pl-lg-5 mt-4 mt-lg-0">
                <li>
                  <h4 className="h6 h5-md">• Força</h4>
                </li>
                <li>
                  <h4 className="h6 h5-md">• Potência</h4>
                </li>
                <li>
                  <h4 className="h6 h5-md">• Agilidade</h4>
                </li>
                <li>
                  <h4 className="h6 h5-md">• Flexibilidade</h4>
                </li>
                <li>
                  <h4 className="h6 h5-md">• Mobilidade</h4>
                </li>
                <li>
                  <h4 className="h6 h5-md">• Resistência</h4>
                </li>
              </ul>

              <h3 className="display-lg-4 font-weight-bold mb-4 titulo">
                <span className="h1 display-lg-3 font-weight-bold">
                  PREVENÇÃO
                </span>
                <br />
                de lesões como:
              </h3>

              <ul className="pl-3 pl-lg-5">
                <li>
                  <h4 className="h6 h5-md">• Fascite plantar</h4>
                </li>
                <li>
                  <h4 className="h6 h5-md">• Canelite</h4>
                </li>
                <li>
                  <h4 className="h6 h5-md">
                    • Condromalácia/condropatia patelar
                  </h4>
                </li>
                <li>
                  <h4 className="h6 h5-md">• Síndrome do trato-iliotibial</h4>
                </li>
                <li>
                  <h4 className="h6 h5-md">• Bursite trocantérica</h4>
                </li>
              </ul>
              <p className="h5 h6-md py-4 py-lg-0">
                E muitas outras lesões provenientes do excesso ou erros
                biomecânicos da corrida.{' '}
              </p>
              <ul className="pl-3 pl-lg-5">
                <li>
                  <h4 className="h6 h5-md mb-3">• Longevidade esportiva;</h4>
                </li>
                <li>
                  <h4 className="h6 h5-md mb-3">
                    • Incentivo à prática da corrida de forma saudável e
                    sustentável;
                  </h4>
                </li>
                <li>
                  <h4 className="h6 h5-md mb-3">
                    • Melhora da performance nos treinos, corridas e maratonas.
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </BgGray>
      <Container>
        <div className="container">
          <div className="row justify-content-center align-items-center pt-5 pt-lg-0">
            <div className="col-lg-5 pl-lg-4 pr-lg-0">
              <h2 className="display-lg-4 font-weight-bold titulo pb-4 pb-lg-0">
                Benefícios do
                <br />
                <span className="h1 display-lg-3 font-weight-bold">
                  PROGRAMA:
                </span>
              </h2>
              <ul className="pl-lg-5">
                <li>
                  <h3 className="h4 h5-md mb-lg-2 mb-xxl-3 red">
                    • Reeducação postural na corrida
                  </h3>
                </li>
                <p className="h5 h6-md pb-4 pb-lg-0">
                  Correção postural e biomecânica da corrida, composto de 1
                  treino corretivo, 1x por semana, durante 10 semanas. Após as
                  10 sessões, realizamos a reavaliação biomecânica para medir os
                  resultados.
                </p>
                <li>
                  <h3 className="h4 h5-md mb-lg-2 mb-xxl-3 red">
                    • Runner performance
                  </h3>
                </li>
                <p className="h5 h6-md">
                  Correção postural e biomecânica da corrida, composto de 1
                  treino corretivo mais 2 treinos funcionais 3x por semana,
                  durante 12 semanas. Após as 36 sessões, realizamos a
                  reavaliação biomecânica para medir os resultados e seguir
                  aperfeiçoando a técnica.
                </p>
              </ul>
            </div>
            <div className="col-lg-7 pl-lg-0">
              <img src={recovery} alt="Atleta correndo" />
            </div>
          </div>
        </div>
      </Container>
      <Fisioterapia>
        <div className="container-fluid">
          <div className="row justify-content-start">
            <div className="col-lg-6 pl-lg-0">
              <div className="poligonal h-100 w-100">
                <div className="h-100 w-100" />
              </div>
            </div>
            <div className="col-lg-4 col-xxl-3 py-0 py-lg-5 my-5">
              <h2 className="display-lg-4 font-weight-bold mb-4">
                Fisioterapia
                <br />
                <span className="h1 display-lg-3 font-weight-bold">
                  ESPORTIVA
                </span>
              </h2>

              <p className="h5 h6-md pb-4 pb-lg-0">
                A fisioterapia esportiva é uma área da medicina que atua na
                orientação, prevenção e recuperação de lesões decorrentes de
                atividades físicas, seja ela amadora ou profissional.
              </p>

              <p className="h5 h6-md pb-4 pb-lg-0">
                Para aqueles que estão iniciando a prática de atividades
                regulares é de suma importância ter a orientação necessária para
                prevenir possíveis lesões e dores, evitando assim que acabem
                sendo desestimulados e/ou que tenham melhores resultados.
              </p>

              <p className="h5 h6-md">
                Já para atletas amadores ou profissionais é inquestionável a
                importância da fisioterapia esportiva, que acaba não só atuando
                na orientação e prevenção como na recuperação de possíveis
                lesões que ocorrem em pessoas que procuram alcançar ao máximo
                sua performance.
              </p>
            </div>
          </div>
        </div>
        <img
          src={fisioterapia}
          alt="Fisioterapia"
          className="d-block d-lg-none"
        />
      </Fisioterapia>
      <Equipamentos />
      <Contato />
    </>
  );
}

export default Atletas;
