import React from 'react';
import { Helmet } from 'react-helmet';

import Slider from 'react-slick';

import {
  Container,
  BgGray,
  Medicina,
  Consultorio,
  Estrutura,
  Experiencia,
} from './styles';
import Contato from '~/components/Contato';

import veiculo from '~/assets/icones/consultorio-carro.svg';
import ar from '~/assets/icones/consultorio-ar.svg';
import wifi from '~/assets/icones/consultorio-wifi.svg';
import tecnologia from '~/assets/icones/consultorio-tecnologia.svg';
import experiencia from '~/assets/metodos/consultorio-experiencia.png';
import atendimento from '~/assets/metodos/consultorio-atendimento.png';
import medicina from '~/assets/metodos/consultorio-medicina.png';
import consultorio1 from '~/assets/metodos/consultorio-1.png';
import consultorio2 from '~/assets/metodos/consultorio-2.png';
import consultorio3 from '~/assets/metodos/consultorio-3.png';

import estrutura1 from '~/assets/metodos/consultorio-estru-1.png';
import estrutura2 from '~/assets/metodos/consultorio-estru-2.png';
import estrutura3 from '~/assets/metodos/consultorio-estru-3.png';
import estrutura4 from '~/assets/metodos/consultorio-4.png';
import estrutura5 from '~/assets/metodos/consultorio-5.png';
import estrutura6 from '~/assets/metodos/consultorio-6.png';
import estrutura7 from '~/assets/metodos/consultorio-7.png';
import estrutura8 from '~/assets/metodos/consultorio-8.png';

function Consultorios() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  const settingsMobile = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: false,
    prevArrow: false,
  };
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – Consultórios</title>
          <meta
            name="description"
            content="Clínica Physic especialista nas áreas em Ortopedia, Nutrição, Fisioterapia e Psicologia. Agende sua consulta no bairro Ibirapuera."
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  CONSULTÓRIOS
                </h1>
                <h2 className="h4 h2-md text-white text-center h4 normal bold">
                  Atendimento personalizado, profissionais <br />
                  de alta performance
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 order-1 order-lg-0">
              <img src={experiencia} alt="Medicos profissionais" />
            </div>
            <div className="col-lg-5 m-lg-0 m-lg-auto text-center text-lg-left order-0 order-lg-1">
              <h2 className="h1 display-lg-4 display-xxl-3 font-weight-bold mg-borda">
                <span data-texto="EXPERIÊNCIA" className="absolute-mobile">
                  EXPERIÊNCIA
                </span>
              </h2>
              <p className="h5 h6-md pt-5 pt-lg-0 mt-5 mt-lg-0 mb-4 mb-lg-0">
                Aqui na Physic você conta com médicos e profissionais
                qualificados em um ambiente acolhedor com consultórios e
                equipamentos de última geração para atender nossos pacientes com
                conforto e máxima qualidade.
              </p>
            </div>
          </div>
        </div>
      </Container>
      <BgGray>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 text-center text-lg-left">
              <h2 className="h1 display-lg-4 display-xxl-3 font-weight-bold mg-borda">
                <span
                  data-texto="ATENDIMENTO PERSONALIZADO"
                  className="absolute-mobile"
                >
                  ATENDIMENTO <br />
                  PERSONALIZADO
                </span>
              </h2>
              <p className="h5 h6-md mt-9 mb-4 mb-lg-0">
                Nossos consultórios oferecem privacidade para que cada paciente
                tenha um atendimento personalizado e especializado para que seu
                tratamento seja eficaz dentro das suas necessidades.
              </p>
            </div>
            <div className="col-lg-6">
              <img src={atendimento} alt="Atendimento personalizado" />
            </div>
          </div>
        </div>
      </BgGray>
      <Medicina>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 order-1 order-lg-0">
              <img src={medicina} alt="Medicina e fisioterapia" />
            </div>
            <div className="col-lg-5 m-lg-0 m-lg-auto order-0 order-lg-1 text-center text-lg-left">
              <h2 className="h1 display-lg-4 display-xxl-3 font-weight-bold mg-borda">
                <span
                  data-texto="MEDICINA E FISIOTERAPIA"
                  className="absolute-mobile"
                >
                  MEDICINA E <br />
                  FISIOTERAPIA
                </span>
              </h2>
              <p className="h5 h6-md mt-9 mb-4 mb-lg-0">
                Unimos diversas especialidades da área da saúde a fim de
                oferecer para os nossos pacientes um tratamento completo.
                Trabalhamos para desenvolver a performance, aliviar a dor,
                recuperar funcionalidade, prevenir lesões e cuidar do bem-estar
                de cada um de forma única.
              </p>
            </div>
          </div>
        </div>
      </Medicina>
      <Consultorio>
        <div className="container py-5">
          <div className="row">
            <div className="col text-center">
              <h2 className="titulo display-lg-4 mb-3 font-weight-bold">
                Conheça nossos <br />
                <span className="h1 display-lg-3 font-weight-bold">
                  CONSULTÓRIOS
                </span>
              </h2>
            </div>
          </div>
          <div className="d-none d-lg-block">
            <Slider {...settings}>
              <div className="px-4">
                <img src={consultorio1} alt="Foto consultorio" />
              </div>
              <div className="px-4">
                <img src={consultorio2} alt="Foto consultorio" />
              </div>
              <div className="px-4">
                <img src={consultorio3} alt="Foto consultorio" />
              </div>
              {/* <div className="px-4">
                <img src={consultorio1} alt="Foto consultorio" />
              </div>
              <div className="px-4">
                <img src={consultorio2} alt="Foto consultorio" />
              </div>
              <div className="px-4">
                <img src={consultorio3} alt="Foto consultorio" />
              </div> */}
            </Slider>
          </div>

          <div className="d-block d-lg-none">
            <Slider {...settingsMobile}>
              <div className="px-4">
                <img src={consultorio1} alt="Foto consultório" />
              </div>
              <div className="px-4">
                <img src={consultorio2} alt="Foto consultório" />
              </div>
              <div className="px-4">
                <img src={consultorio3} alt="Foto consultório" />
              </div>
            </Slider>
          </div>
        </div>
      </Consultorio>
      <Experiencia>
        <div className="container">
          <div className="row py-4 py-lg-0">
            <div className="col-lg-6 col-xxl-5">
              <h2 className="h1 display-lg-3 font-weight-bold">
                <span data-texto="EXPERIÊNCIA">EXPERIÊNCIA</span>
              </h2>
              <h2 className="h4 h3-md py-4 py-lg-0 font-italic font-weight-bold color">
                Instalações modernas, equipamentos de última geração
              </h2>
              <p className="h5 h6-md">
                Temos salas de fisioterapia, consultórios médicos, laboratório
                de análise do movimento, sala de Pilates, área externa para
                treinamento funcional e academia completa, com os melhores
                equipamentos para atender nossos clientes e pacientes de forma
                única, com conforto e qualidade.{' '}
              </p>
              <div className="d-flex pt-4">
                <div className="col-lg-6 text-center">
                  <img src={veiculo} alt="Valet gratuito" />
                  <h3 className="h6 color mt-3">Valet gratuito</h3>
                </div>
                <div className="col-lg-6 text-center">
                  <img src={wifi} alt="Wi-fi" className="mt-4 pt-2" />
                  <h3 className="h6 color mt-3 mt-lg-4 pt-1 pt-lg-0">Wi-fi</h3>
                </div>
              </div>
              <div className="d-flex pt-4">
                <div className="col-lg-6 text-center">
                  <img src={ar} alt="Ambientes climatizados" className="mt-3" />
                  <h3 className="h6 color mt-3 pt-1 pt-lg-0">
                    Ambientes são <br />
                    climatizados
                  </h3>
                </div>
                <div className="col-lg-6 text-center">
                  <img
                    src={tecnologia}
                    alt="Equipamentos com tecnologia de ponta"
                    className="mt-1"
                  />
                  <h3 className="h6 color mt-3">
                    Equipamentos com <br />
                    tecnologia de ponta
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Experiencia>
      <Estrutura>
        <div className="container py-5">
          <div className="row">
            <div className="col text-center">
              <h2 className="titulo display-lg-4 mb-3 font-weight-bold">
                Conheça nossa <br />
                <span className="display-lg-3 font-weight-bold">ESTRUTURA</span>
              </h2>
            </div>
          </div>
          <div className="d-none d-lg-block">
            <Slider {...settings}>
              <div className="px-4">
                <img src={estrutura1} alt="Foto fachada" />
              </div>
              <div className="px-4">
                <img src={estrutura2} alt="Foto recepção" />
              </div>
              <div className="px-4">
                <img src={estrutura3} alt="Foto esteira" />
              </div>
              <div className="px-4">
                <img src={estrutura4} alt="Foto esteira" />
              </div>
              <div className="px-4">
                <img src={estrutura5} alt="Foto equipamento" />
              </div>
              <div className="px-4">
                <img src={estrutura6} alt="Foto equipamento" />
              </div>
              <div className="px-4">
                <img src={estrutura7} alt="Foto equipamento" />
              </div>
              <div className="px-4">
                <img src={estrutura8} alt="Foto baropodometria" />
              </div>
            </Slider>
          </div>

          <div className="d-block d-lg-none">
            <Slider {...settingsMobile}>
              <div className="px-4">
                <img src={estrutura1} alt="Foto estrutura" />
              </div>
              <div className="px-4">
                <img src={estrutura2} alt="Foto estrutura" />
              </div>
              <div className="px-4">
                <img src={estrutura3} alt="Foto estrutura" />
              </div>
              <div className="px-4">
                <img src={estrutura4} alt="Foto esteira" />
              </div>
              <div className="px-4">
                <img src={estrutura5} alt="Foto equipamento" />
              </div>
              <div className="px-4">
                <img src={estrutura6} alt="Foto equipamento" />
              </div>
              <div className="px-4">
                <img src={estrutura7} alt="Foto equipamento" />
              </div>
              <div className="px-4">
                <img src={estrutura8} alt="Foto baropodometria" />
              </div>
            </Slider>
          </div>
        </div>
      </Estrutura>

      <Contato />
    </>
  );
}

export default Consultorios;
