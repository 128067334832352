import React, { useState, useEffect } from 'react';
import { Input } from '@rocketseat/unform';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

export default function MaskInput({ mask, name, value, required, className }) {
  const [newValue, setNewValue] = useState('');
  const [maskEdit, setMaskEdit] = useState(false);
  const [newMask, setNewMask] = useState('');
  // const [qtd, setQtd] = useState(0);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  function handleChange(e) {
    const alvo = e.target.value;

    setMaskEdit(true);
    setNewMask('(99) 99999-9999');

    setNewValue(alvo);
  }

  function handleKeyPress() {
    setMaskEdit(true);
    setNewMask('(99) 99999-9999');
  }

  return (
    <InputMask
      mask={maskEdit ? newMask : mask}
      value={newValue}
      onChange={handleChange}
    >
      {() => (
        <Input
          name={name}
          placeholder="(00)00000-0000"
          className={className}
          required={required}
          onKeyPress={handleKeyPress}
        />
      )}
    </InputMask>
  );
}

MaskInput.propTypes = {
  mask: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
};

MaskInput.defaultProps = {
  className: '',
  value: '',
  required: false,
};
