import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Equipamentos from '~/components/Equipamentos';
import Contato from '~/components/Contato';

import { Container, BgGrayInvert } from './styles';

import voltar from '~/assets/icones/icone-voltar.svg';
import ergonomia from '~/assets/metodos/ergonomia.png';
import trabalho from '~/assets/metodos/trabalho.png';

function Ergonomia() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – Ergonomia</title>
          <meta
            name="description"
            content="Clínica Physic: Soluções em ergonomia para aplicação em empresas e home office para aliviar dores nas costas e prevenir lesões."
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  ERGONOMIA <br className="d-block d-md-none" />
                  PHYSIC
                </h1>
                <div className="text-center mt-5 mt-lg-4">
                  <Link
                    to={`${process.env.PUBLIC_URL}/especialidades`}
                    className="text-white h4"
                  >
                    <img src={voltar} alt="Icone voltar" className="mr-2" />
                    Voltar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-5 justify-content-center align-items-center">
            <div className="col-lg-6">
              <div>
                <h2 className="h3 font-weight-bold mb-4 text-center text-lg-left">
                  ERGONOMIA PARA APLICAÇÃO <br className="d-none d-md-block" />
                  EM EMPRESAS E HOME&nbsp;OFFICE
                </h2>
                <img
                  src={ergonomia}
                  alt="Mulher sentada"
                  className="d-block d-lg-none my-4"
                />
                <p className="h5 h6-md mb-3">
                  Temos um corpo clínico especialista para auxiliar na
                  implementação de soluções em ergonomia para empresas e também
                  para adaptação do trabalho no home office.
                </p>
                <p className="h5 h6-md mb-3">
                  A prática da boa gestão ergonômica contribui para um trabalho
                  mais produtivo e saudável, garantindo mais bem-estar para os
                  colaboradores.
                </p>
                <p className="h5 h6-md mb-3">
                  Os princípios da aplicação seguem a Norma Regulamentadora 17,
                  que busca aumentar a qualidade de vida dos colaboradores,
                  evitando danos à saúde física e mental. Logo, a aplicabilidade
                  da ergonomia, permite a adaptação das condições de trabalho às
                  características psicofisiológicas dos trabalhadores, de modo a
                  proporcionar o máximo de conforto, segurança e desempenho
                  eficiente.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={ergonomia}
                alt="Mulher sentada"
                className="d-none d-lg-block"
              />
            </div>
          </div>
        </div>
      </Container>
      <BgGrayInvert>
        <img
          src={trabalho}
          alt="Pessoas trabalhando"
          className="d-block d-lg-none my-3"
        />
        <div className="container-fluid">
          <div className="row justify-content-center justify-content-lg-start">
            <div className="col-lg-6 pl-lg-0">
              <div className="poligonal h-100 w-100">
                <div className="h-100 w-100" />
              </div>
            </div>
            <div className="col col-sm-9 col-lg-4 col-xxl-4 py-5">
              <h2 className="h3 h2-lg font-weight-bold mb-4">
                SERVIÇOS PRESTADOS:
              </h2>
              <ul>
                <li>
                  <h3 className="h5">• Análise Ergonômica do Trabalho (AET)</h3>
                </li>
                <p>
                  Análise para prever os possíveis fatores de riscos
                  biomecânicos, ambientais, organizacionais e cognitivos das
                  atividades produtivas, sejam administrativas e/ou industriais,
                  através de um mapeamento ergonômico.
                </p>
                <li>
                  <h3 className="h5">• Gestão de ergonomia</h3>
                </li>
                <p>
                  São ações que tem por objetivo implantar medidas preventivas
                  e/ou corretivas, treinamentos em conformidade com a NR17 e
                  programas preventivos a partir da análise ergonômica do tralho
                  (AET) e, ainda, instruir a conscientização e adesão dos
                  colaboradores ao respectivo projeto.
                </p>
                <li>
                  <h3 className="h5">• Treinamento de ergonomia</h3>
                </li>
                <p>
                  Reunião para conscientizar e instruir os colaboradores sobre o
                  uso correto dos equipamentos de trabalho e proporcionar
                  informação a respeito das boas práticas ergonômicas.
                </p>
                <li>
                  <h3 className="h5">• Canal para orientações posturais</h3>
                </li>
                <p>
                  Reunião para conscientizar os colaboradores sobre a
                  importância em adotar boas práticas posturais durante a
                  execução das atividades produtivas.
                </p>
              </ul>
            </div>
          </div>
        </div>
      </BgGrayInvert>
      <Equipamentos />
      <Contato />
    </>
  );
}

export default Ergonomia;
