import styled from 'styled-components';

import banner from '~/assets/banners-paginas/banner-treinamento.png';
import mobile from '~/assets/banners-paginas/banner-treinamento-mobile.png';

export const Container = styled.div`
  p,
  ul li {
    color: #333;
  }

  ul li {
    line-height: 2rem;
  }

  .color,
  h2 {
    color: #841619;
  }
  .banner {
    background-image: url(${banner});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
    margin-top: 31px;
  }

  .mg-top {
    margin-top: 180px;
  }

  @media screen and (min-width: 1400px) {
    .mg-top {
      margin-top: 210px;
    }
  }

  @media screen and (max-width: 768px) {
    .banner {
      background-image: url(${mobile});
      background-position: top right;
    }

    .mg-top {
      margin-top: 250px;
    }
  }

  @media screen and (max-width: 575px) {
    .mg-top {
      margin-top: 290px;
    }
  }
`;
