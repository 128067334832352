import React from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

import Equipamentos from '~/components/Equipamentos';
import Contato from '~/components/Contato';

import { Container, BgGrayInvert, BgGray } from './styles';

import voltar from '~/assets/icones/icone-voltar.svg';
import corrida from '~/assets/metodos/corrida.png';
import assessoria from '~/assets/metodos/Treinamento-de-Corrida.png';
//import esportiva from '~/assets/metodos/esportiva.png';
import denis from '~/assets/metodos/denis-2.png';
//import { string } from 'prop-types';
//import tratamento from '~/assets/metodos/mulher-rpg.png';

function Rpg() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – Assessoria de Corrida</title>
          <meta
            name="description"
            content="Clínica Physic."
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  ASSESSORIA DE CORRIDA
                </h1>
                <h2 className="h4 h2-md my-5 my-lg-0 text-white font-italic text-center h4 font-weight-bold">
                Treine de onde quiser e potencialize sua performance com nosso <br />
                acompanhamento profissional
                </h2>
                <div className="text-center mt-lg-4">
                  <Link
                    to={`${process.env.PUBLIC_URL}/especialidades`}
                    className="text-white h4"
                  >
                    <img src={voltar} alt="Icone voltar" className="mr-2" />
                    Voltar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5">
              <h2 className="h4 h3-md font-weight-bold mt-4 mt-lg-0 mb-4 text-center text-lg-left">
                SUA ALTA PERFORMANCE <br/>
                EM CORRIDAS DE RUA 
              </h2>
              <img
                src={assessoria}
                alt="Assessoria de Corrida"
                className="d-block d-lg-none mb-3"
              />
              <p className="h5 h6-md mb-3">
              O Treinamento de Corrida foi criado com o objetivo de <br/>
               potencializar seu desempenho em treinos e provas de corrida, <br/> 
               estimulando sua intensidade, qualidade e regularidade.
              </p>
              <p className="h5 h6-md mb-3">
              Faça suas atividades de onde preferir e receba o <br/>
              acompanhamento profissional do nosso especialista em <br/> 
              Educação Física e Fisiologia e Corridas, Denis Bento. 
              </p>
            </div>
            <div className="col-lg-7 d-none d-lg-block">
              <img src={assessoria} alt="Assessoria de Corrida" />
            </div>
          </div>
        </div>
      </Container>
      <BgGrayInvert>
        <div className="container-fluid">
          <div className="row justify-content-center justify-content-lg-start">
            <div className="col-lg-6 pl-lg-0">
              <div className="poligonal h-100 w-100">
                <div className="h-100 w-100" />
              </div>
            </div>
            <div className="col col-sm-9 col-lg-4 col-xxl-4 py-4 py-lg-5 my-lg-5">
              <h2 className="h3 h1-md font-weight-bold mb-4">
              Como funciona o processo?
              </h2>
              <p className="txt  font-weight-bold">
              Envie sua agenda de provas 
              </p>
              <p className="h5 h6-md">
              Compartilhe seu calendário para organizarmos sua <br/> programação de treinamentos antes do início para as <br/> competições. 
              </p>

              <p className="txt  font-weight-bold">
              Faça a sua avaliação física 
              </p>
              <p className="h5 h6-md">
              Realize sua avaliação física, biomecânica e funcional. Os <br/> 
              parâmetros dos treinamentos, como intensidade, <br/>
              frequência e controle de carga, serão feitos baseados no <br/>
              seu teste. Vamos desenvolver suas habilidades e promover <br/>
              a sua performance. 
              </p>

              <p className="txt  font-weight-bold">
              Você receberá sua planilha de treinamento
              </p>
              <p className="h5 h6-md">
              Vamos elaborar e enviar suas planilhas de treinamento. Elas <br/> 
              serão compartilhadas semanalmente, às segundas-feiras. <br/> 
              Neste dia, você deverá se pesar após acordar e enviar o <br/>
              valor do peso corporal ao treinador. A programação pode <br/>
              ser realinhada a qualquer momento. 
              </p>

              <p className="txt  font-weight-bold">
              Compartilhe seu feedback treino a treino
              </p>
              <p className="h5 h6-md">
              Após cada atividade, você deverá informar ao treinador a <br/> 
              sua percepção de esforço. Você receberá uma tabela para <br/> 
              compartilhar as informações, assim, vamos ajustar os <br/>
              treinamentos de acordo com sua performance e <br/>
              resultados.
              </p>
            </div>
          </div>
        </div>
        <img
          src={corrida}
          alt="Assessoria de Corrida"
          className="d-block d-lg-none"
        />
      </BgGrayInvert>
      <Container>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <br/>
            </div>
          </div>
        </div>
      </Container>
      <BgGray>
        <div className="container-fluid">
          <div className="row justify-content-center justify-content-lg-end">
            <div className="col col-sm-9 col-lg-4 col-xxl-4 py-5 py-lg-5 my-lg-5">
              <h2 className="txt h1-md font-weight-bold mb-4">
              Denis Gonçalves Bento <br/>
             <string className="txt-2"> CREF 094068 </string>
              </h2>
              <ul className="pl-lg-5">
                <li className="h5">• Atua como Fisiologista na H5 Health e Treinador <br/> de Corrida na Physic;</li>
                <li className="h5">
                  • Graduação em Educação Física, Licenciatura e <br/> Bacharelado (Uninove – 2012);
                </li>
                <li className="h5">
                  • Fisiologia do Exercício (Universidade Federal de <br/> São Paulo – 2014).
                </li>
              </ul>
              <br/>
              <p className="pl-lg-5">
                Formações em: <br/>
                Corrida de Rua, Avaliação Física, Treinamento Funcional e Musculação
              </p>
            </div>
            <div className="col-lg-6 pr-lg-0">
              <div className="poligonal h-100 w-100">
                <div className="h-100 w-100" />
              </div>
            </div>
          </div>
        </div>
        <img
          src={denis}
          alt="Colaborador Denis Gonçalves"
          className="d-block d-lg-none"
        />
      </BgGray>
      <Equipamentos />
      <Contato />
    </>
  );
}

export default Rpg;
