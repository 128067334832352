import styled from 'styled-components';

import banner from '~/assets/banners-paginas/banner-fisioterapia.png';
import mobile from '~/assets/banners-paginas/banner-fisioterapia-mobile.png';

export const Container = styled.div`
  .color,
  p {
    color: #333;
  }

  h2,
  h3 {
    color: #841619;
  }

  .banner {
    background-image: url(${banner});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
    margin-top: 31px;
  }

  .mg-top {
    margin-top: 180px;
  }

  @media screen and (max-width: 768px) {
    .banner {
      background-image: url(${mobile});
      background-position: center right;
    }

    .mg-top {
      margin-top: 230px;
    }
  }

  @media screen and (max-width: 575px) {
    .mg-top {
      margin-top: 270px;
    }
  }
`;

export const BgGray = styled.div`
  background-color: #e7e7e8;
  z-index: 0;
  position: relative;

  h2 {
    span::after {
      color: transparent;
      -webkit-text-stroke: 1px rgba(167, 171, 173, 0.52);
      display: block;
      content: attr(data-texto);
      z-index: -1;
      position: absolute;
      bottom: 6px;
      left: 119px;
    }
  }

  .color,
  p {
    color: #333;
  }

  h2,
  h3 {
    color: #841619;
  }

  @media screen and (min-width: 1400px) {
    h2 {
      span::after {
        left: 171px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    h2 {
      span::after {
        left: 67px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      span::after {
        left: -3px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    h2 {
      span::after {
        left: -56px;
      }
    }
  }

  @media screen and (max-width: 575px) {
    h2 {
      span {
        margin-left: 3px;
      }
      span::after {
        left: auto;
      }
    }
  }
`;
