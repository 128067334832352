import styled from 'styled-components';
import banner from '~/assets/banners-paginas/banner-ortopedia.png';
import mobile from '~/assets/banners-paginas/banner-ortopedia-mobile.png';

export const Container = styled.div`
  h2,
  h3,
  h4 {
    color: #841619;
  }

  p {
    color: #333;
  }

  .banner {
    background-image: url(${banner});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
    margin-top: 31px;
  }

  .mg-top {
    margin-top: 150px;
  }

  @media screen and (min-width: 1400px) {
    .mg-top {
      margin-top: 200px;
    }
  }

  @media screen and (max-width: 768px) {
    .banner {
      background-image: url(${mobile});
      background-position: center right;
    }

    .mg-top {
      margin-top: 200px;
    }
  }

  @media screen and (max-width: 575px) {
    .mg-top {
      margin-top: 270px;
    }
  }
`;
