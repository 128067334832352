import React from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

import Equipamentos from '~/components/Equipamentos';
import Contato from '~/components/Contato';

import voltar from '~/assets/icones/icone-voltar.svg';
import ombro from '~/assets/icones/ombro.svg';
import joelho from '~/assets/icones/joelhos.svg';
import tornozelo from '~/assets/icones/tornozelos.svg';
import quadril from '~/assets/icones/quadril.svg';
import coluna from '~/assets/icones/coluna.svg';

import { Container } from './styles';

function Ortopedia() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – Ortopedia</title>
          <meta
            name="description"
            content="Clínica Physic é especialista no tratamento de lesões e traumas na área da ortopedia. Marque sua consulta no bairro Ibirapuera."
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  ORTOPEDIA
                </h1>
                <h2 className="text-white font-italic text-center my-5 my-lg-0 h5 h4-md font-weight-bold">
                  Tratamento de lesões musculares, traumas ortopédicos e
                  fraturas
                </h2>
                <div className="text-center mt-lg-4">
                  <Link
                    to={`${process.env.PUBLIC_URL}/especialidades`}
                    className="text-white h4"
                  >
                    <img src={voltar} alt="Icone voltar" className="mr-2" />
                    Voltar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center mt-5">
              <h3 className="h2-lg h4 font-weight-bold">
                UM TIME ESPECIALIZADO EM&nbsp;LESÕES:
              </h3>
            </div>
          </div>
          <div className="row justify-content-center justify-content-lg-between mt-5">
            <div className="col-4 col-lg-2 text-center">
              <img src={ombro} alt="Ombro" />
              <h4 className="h6 h4-md mt-3">Ombro</h4>
            </div>
            <div className="col-4 col-lg-2 text-center">
              <img src={joelho} alt="Ombro" />
              <h4 className="h6 h4-md mt-3">Joelhos</h4>
            </div>
            <div className="col-4 col-lg-2 text-center">
              <img src={tornozelo} alt="Ombro" />
              <h4 className="h6 h4-md mt-3">Tornozelos/pé</h4>
            </div>
            <div className="col-4 col-lg-2 text-center">
              <img src={quadril} alt="Ombro" />
              <h4 className="h6 h4-md mt-3">Quadril</h4>
            </div>
            <div className="col-4 col-lg-2 text-center">
              <img src={coluna} alt="Ombro" />
              <h4 className="h6 h4-md mt-3">Coluna</h4>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-11 col-lg-8 my-5 text-center text-lg-left">
              <p className="h5 h6-md">
                Nossa equipe de profissionais é formada por médicos
                ortopedistas, especialistas em atender e tratar as mais diversas
                lesões e traumas ortopédicos.
              </p>
              <p className="h5 h6-md mt-4">
                Trabalhamos com cirurgias e reabilitações para tratamentos e a
                prevenção de lesões musculoesqueléticas como: fraturas, lesões
                de ligamento, de cartilagem, musculares, entre&nbsp;outras.{' '}
              </p>
            </div>
          </div>
        </div>
      </Container>
      <Equipamentos />
      <Contato />
    </>
  );
}

export default Ortopedia;
